import moment from 'moment'


export const isIterableArray = (arr: Array<any | undefined> | undefined) => Array.isArray(arr) && !!arr.length;

export const toFormatPrice = (price: number | undefined, fixedLength: number = 2) => {

    if (price === 0) return '0,00'
    if (!price) return price

    const trunc = Math.trunc(price) // целая часть 
    const decimal = Math.abs(price - trunc) // модуль дробной части
    const trunc_format = trunc.toLocaleString('ru-RU')  // целая часть в формате с отступами

    let decimal_format
    if (fixedLength) decimal_format = decimal.toFixed(fixedLength).replace('0.', ',') // дробная часть в формате с 2 знаками после запятой
    else decimal_format = String(decimal).replace('0.', ',')

    return trunc_format + decimal_format
}
export const toStringPrice = (price: any) => {
    if (!price) return price
    return price.toLocaleString('ru-RU')
}

export const toTableDate = (date: string) => {

    // const { t } = useTranslation()
    const serverFormat = 'yyyy-mm'
    const month = moment(date, serverFormat).format('mm')
    const year = moment(date, serverFormat).format('YY')

    const monthText = `short_month_${month}`

    return monthText + ' - ' + year
}

export const toDate = (date: string, formatIn: string = 'YYYY-MM-DD', formatOut: string = 'DD.MM.YYYY') => {
    const dateText = moment(date, formatIn).format(formatOut)
    return dateText
}

export const numWord = (val: number) => {
    const value = Math.abs(val) % 100;
    const num: number = value % 10;
    if (value > 10 && value < 20) return 3; // (-ов)
    if (num > 1 && num < 5) return 2; // (-a)
    if (num === 1) return 1; // (-)
    return 3; // (-ов)
}

export const blobToBase64 = (blob: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};