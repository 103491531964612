
import { Spinner, Typography } from '@material-tailwind/react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { toFormatPrice, toStringPrice } from '../../utils/utils'
import { useTranslation } from 'react-i18next'

const MobileAmountTitle = ({ text, amount, amountTitle, tkos_length, isFetching }: { tkos_length?: number, text: any, amount: any, amountTitle: string, isFetching: boolean }) => {

    const { t } = useTranslation()
    const service_id = useSelector((state: RootState) => state.contract.service_id)
    const textService = service_id === 1 ? 'text-orange-500' : service_id === 2 ? 'text-blue-600' : 'text-gray-800'
    const spinnerColor = service_id === 1 ? 'orange' : service_id === 2 ? 'blue' : 'gray'

    return (
        <div className='flex flex-col gap-[8px] bg-white border-2 border-gray-100 p-[24px] rounded-[16px]'>
            {
                typeof tkos_length === 'number' &&
                <div className='flex justify-between items-center gap-[12px]'>
                    <Typography variant='lead' className='text-gray-700 font-normal text-[14px]'>
                        {t('declared.tkos_length')}
                    </Typography>
                    <Typography variant='lead' className='text-gray-800 font-medium text-[14px]'>
                        {tkos_length || 0}
                    </Typography>
                </div>
            }
            <div className='flex justify-between items-center gap-[12px]'>
                <Typography variant='lead' className='text-gray-700 font-normal text-[14px]'>
                    {text}
                </Typography>
                <Typography variant='lead' className={`text-gray-800 font-medium text-[14px] whitespace-nowrap`}>
                    {
                        isFetching ?
                            <Spinner color={spinnerColor} className='min-h-[1.5em]' />
                            :
                            <span className='flex align-baseline gap-[4px]'>
                                {toStringPrice(amount || 0)} {amountTitle}
                            </span>
                    }
                </Typography>
            </div>





        </div>
    )
}

export default MobileAmountTitle
