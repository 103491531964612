import { Fragment, useState } from 'react'
import { InvoiceHistoryRowType } from '../InvoicePage.types'
import { isIterableArray, toFormatPrice } from '../../../utils/utils'
import { Button, IconButton, Spinner, Typography } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import DrawerCard from '../../../components/mobile/DrawerCard'
import CloseDrawerIcon from '../../../images/icons/CloseDrawerIcon'
import CopyButton from '../../tableHelpers/buttons/CopyButton'

type propType = {
    visibleRows: InvoiceHistoryRowType[],
    isLoadingTable: boolean,
}
const InvoiceHistoryCards = ({
    visibleRows,
    isLoadingTable,
}: propType) => {

    const { t } = useTranslation()

    const [isOpenCard, setIsOpenCard] = useState<boolean>(false);
    const [modalValue, setModalValue] = useState<InvoiceHistoryRowType | null>(null);

    const closeDrawer = () => {
        setIsOpenCard(false)
        setModalValue(null)
    }
    const openDrawer = (row: InvoiceHistoryRowType) => {
        setIsOpenCard(true)
        setModalValue(row)
    }

    const dateParser = (date: string) => {
        const month = moment(date, 'MM-YYYY').format('MM')
        const year = moment(date, 'MM-YYYY').format('YYYY')
        return t(`month_${month}`) + ' ' + year
    }

    return (
        <div className='tab:hidden pt-[30px]'>
            <DrawerCard
                isOpen={isOpenCard}
                closeDrawer={closeDrawer}
            // modalValue={modalValue}
            >
                {
                    modalValue &&
                    <Fragment>
                        <div className="mb-6 flex items-center justify-end">

                            <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={closeDrawer}>
                                <CloseDrawerIcon />
                            </IconButton>
                        </div>
                        <div className='flex flex-col gap-[10px] mb-6'>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`invoices_page.history.action`)}</span>
                                <span className='text-[14px] font-medium'> {modalValue.action}</span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`invoices_page.history.date`)}</span>
                                <span className='text-[14px] font-medium'> {modalValue.date}</span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`invoices_page.history.amount`)}</span>
                                <span className={`text-[16px] font-bold text-gray-700`}>
                                    {toFormatPrice(Number(modalValue.amount))}
                                </span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`invoices_page.history.debt`)}</span>
                                <span className={`text-[16px] font-bold text-gray-700`}>
                                    {toFormatPrice(Number(modalValue.debt))}
                                </span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`invoices_page.history.saldo`)}</span>
                                <span className={`text-[16px] font-bold text-gray-700`}>
                                    {toFormatPrice(Number(modalValue.saldo))}
                                </span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`invoices_page.history.overpay_unconfirm`)}</span>
                                <span className={`text-[16px] font-bold text-gray-700`}>
                                    {toFormatPrice(Number(modalValue.overpay_unconfirm))}
                                </span>
                            </div>

                            <div className="grid grid-cols-2">
                                <span className='flex items-end gap-[4px] text-[14px] font-medium'>
                                    {t(`invoices_page.history.info`)}
                                    {
                                        modalValue?.info &&
                                        <CopyButton
                                            copyText={String(modalValue?.info) || ''}
                                        />
                                    }
                                </span>
                                <span className='text-[14px] font-gray-600'>{String(modalValue.info)}</span>
                            </div>
                        </div>

                        <Button onClick={closeDrawer} size='sm' color='white' className='w-full !rounded-full min-h-[50px] flex gap-[12px] items-center justify-center'>
                            {t('close')}
                        </Button>
                    </Fragment>
                }
            </DrawerCard>
            {
                isIterableArray(visibleRows) ?
                    visibleRows.map((item: InvoiceHistoryRowType, key: number) => (
                        <Fragment key={key}>


                            <div onClick={() => openDrawer(item)} className="bg-white active:bg-gray-50 transition-all rounded border-2 border-gray-100 p-[12px] mb-[6px] animate-appear">
                                <div className='w-full flex flex-wrap items-center justify-between gap-[4px] mb-[8px]'>
                                    <Typography variant='lead' className='text-[14px] font-bold text-gray-800'>
                                        {item.action}
                                    </Typography>
                                    <span className='text-[12px] p-[4px_8px] rounded-full bg-gray-50  font-bold'> {item.date}</span>
                                    {/* <Checkbox
                                        disableRipple={true}
                                        color="default"
                                        className='svg-checkbox-dark hover:!bg-white'
                                        checked={isSelected(item.id)}
                                        onClick={(event: any) => {
                                            event.stopPropagation()
                                            handleClickCheckbox(event, item.id)
                                        }}
                                    /> */}
                                </div>
                                <div className='flex flex-wrap items-center justify-between gap-[4px]'>
                                    <div className="grid grid-cols-1">
                                        {/* <span className='text-[14px] font-medium'> {t(`invoices_page.amount_with_nds`)}</span> */}
                                        <span className='text-[16px] font-bold'> {toFormatPrice(Number(item.amount))}</span>
                                    </div>
                                    <span className='whitespace-nowrap text-[12px] font-medium'>{t('invoices_page.history.date')} {item.date}</span>
                                </div>
                            </div>
                        </Fragment>
                    ))
                    :
                    isLoadingTable ?
                        <Spinner className='my-6 mx-auto' />
                        :
                        <Typography variant='lead' className='text-[20px] font-medium text-center p-6 '>
                            {t('tables.nothing_to_show')}
                        </Typography>
            }
        </div>
    )
}

export default InvoiceHistoryCards