import { toFormatPrice } from '../utils/utils'

import { useTranslation } from 'react-i18next';
import { currency } from '../utils/variables';
import { Skeleton } from '@mui/material';

type BadgesTypes = {
  isFetching?: boolean,
  service_id: number,
  className?: string,
  contClassName?: string,
  items: {
    [key: string]: number | undefined
  }
}
const def_service_1: {
  [key: string]: number
} = {
  balance_1: 0,
  balance_2: 0,
}
const def_service_2: {
  [key: string]: number
} = {
  balance_3: 0,
  balance_4: 0,
  balance_5: 0,
  balance_6: 0,
}
const BalanceBadges = (props: BadgesTypes) => {

  const { t } = useTranslation()

  const badgeItems = props.isFetching ? props.service_id === 2 ? def_service_2 : def_service_1 : props.items


  return (
    <div className={`flex  gap-[8px] ${props.contClassName || ''}`}>
      {
        badgeItems && Object.keys(badgeItems).map((key) => {
          if (key) {
            const item = badgeItems[key]
            return (
              <div
                key={key}
                className={`
                flex items-center justify-center gap-[4px] rounded border-2 
                py-[8px] px-[12px] tab:py-[15px] tab:px-[25px] min-w-[160px] des:min-w-[220px]
                ${Number(item) >= 0 && ' text-gray-700 bg-white border-gray-100 '} 
                ${Number(item) < 0 && ' text-red-500 !bg-red-50 border-red-100'} 
                ${props.className}
                `}
              >
                <p className='text-[14px] des:text-[16px] text-center tab:whitespace-nowrap'>{t(key)}</p>
                {
                  props.isFetching ?
                    <p className='w-[50%] h-[27px] flex items-center text-[12px] des:text-[18px]'>
                      <Skeleton className='w-full min-w-[50px]' />
                    </p>
                    :
                    <p className='font-bold text-[14px] des:text-[18px] whitespace-nowrap	animate-appear'>{toFormatPrice(item)} {currency}</p>
                }
              </div>
            )
          }
          else return ''
        })
      }
    </div>
  )
}

export default BalanceBadges
