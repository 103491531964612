import { FC, ReactNode } from "react";
import ActsPage from "../pages/acts/ActsPage";
import AnalyticsPage from "../pages/analytics/AnalyticsPage";
import CompaniesPage from "../pages/companies/CompaniesPage";
import ConsumptionsPage from "../pages/consumptions/ConsumptionsPage";
import CreateFactual from "../pages/consumptions/factual/CreateFactual";
import EditFactual from "../pages/consumptions/factual/EditFactual";
import ContractPage from "../pages/contract/ContractPage";
import InvoiceHistory from "../pages/invoices/historyPage/InvoiceHistory";
import InvoicesPage from "../pages/invoices/InvoicesPage";
import MainPage from "../pages/main/MainPage";
import PaymentsPage from "../pages/payments/PaymentsPage";
import CreateDeclaredPage from "../pages/consumptions/declared/CreateDeclaredPage";
import EditDetailing from "../pages/consumptions/detailing/EditDetailing";
import ViewFactualAll from "../pages/consumptions/factual/ViewFactualAll";
import ViewFactual from "../pages/consumptions/factual/ViewFactual";
import ViewDetailing from "../pages/consumptions/detailing/ViewDetailing";
import ViewDetailingAll from "../pages/consumptions/detailing/ViewDetailingAll";
import CreateDetailing from "../pages/consumptions/detailing/CreateDetailing";
import UserProfilePage from "../pages/userProfile/UserProfilePage";
import SupportPage from "../pages/support/SupportPage";
import ViewDeclaredGas from "../pages/consumptions/declared-gas/ViewDeclaredGas";
import ViewDeclaredPower from "../pages/consumptions/declared-power/ViewDeclaredPower";
import ViewDeclaredGasAll from "../pages/consumptions/declared-gas/ViewDeclaredGasAll";
import ViewDeclaredPowerAll from "../pages/consumptions/declared-power/ViewDeclaredPowerAll";
import ViewFactualGas from "../pages/consumptions/factual-gas/ViewFactualGas";
import ViewFactualGasAll from "../pages/consumptions/factual-gas/ViewFactualGasAll";

export const routes: {
    path: string,
    component: FC
}[] = [
        {
            path: '/',
            component: MainPage
        },
        {
            path: '/profile',
            component: UserProfilePage
        },
        {
            path: '/support',
            component: SupportPage
        },
        {
            path: '/my-companies',
            component: CompaniesPage
        },
        {
            path: '/contract/:param_contract_id',
            component: ContractPage
        },
        {
            path: '/invoices/:param_contract_id',
            component: InvoicesPage
        },
        {
            path: '/invoice-history/:invoiceId',
            component: InvoiceHistory
        },
        {
            path: '/payments/:param_contract_id',
            component: PaymentsPage
        },
        {
            path: '/acts/:param_contract_id',
            component: ActsPage
        },
        {
            path: '/analytics/:param_contract_id',
            component: AnalyticsPage
        },
        {
            path: '/consumptions/:param_contract_id',
            component: ConsumptionsPage
        },
        {
            path: '/consumptions/:param_contract_id/detailing/create/:param_tko_id/:param_year/:param_month',
            component: CreateDetailing
        },
        {
            path: '/consumptions/:param_contract_id/detailing/view/:param_cons_id',
            component: ViewDetailing
        },
        {
            path: '/consumptions/:param_contract_id/detailing/edit/:param_tko_id/:param_cons_id',
            component: EditDetailing
        },
        {
            path: '/consumptions/:param_contract_id/view-all-detailing/',
            component: ViewDetailingAll
        },
        {
            path: '/factual/create/:param_tko_id',
            component: CreateFactual
        },
        // {
        //     path: '/factual/edit/:param_tko_id',
        //     component: EditFactual
        // },
        {
            path: '/consumptions/:param_contract_id/view-all-fact/',
            component: ViewFactualAll
        },
        {
            path: '/consumptions/:param_contract_id/view-factual/:param_tko_id',
            component: ViewFactual
        },
        {
            path: '/factual/view/:param_tko_id',
            component: EditFactual
        },
        {
            path: '/consumptions/:param_contract_id/create-declared/',
            component: CreateDeclaredPage
        },
        {
            path: '/consumptions/:param_contract_id/declared-gas/view/:param_cons_id',
            component: ViewDeclaredGas
        },
        {
            path: '/consumptions/:param_contract_id/declared-power/view/:param_cons_id',
            component: ViewDeclaredPower
        },
        {
            path: '/consumptions/:param_contract_id/declared-gas/view-all',
            component: ViewDeclaredGasAll
        },
        {
            path: '/consumptions/:param_contract_id/declared-power/view-all',
            component: ViewDeclaredPowerAll
        },
        {
            path: '/consumptions/:param_contract_id/factual-gas/view/:param_tko_id',
            component: ViewFactualGas
        },
        {
            path: '/consumptions/:param_contract_id/factual-gas/view-all',
            component: ViewFactualGasAll
        },
    ]