import { Button, Typography } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import LightBulbIcon from '../../images/icons/LightBulbIcon'
import PencilIcon from '../../images/icons/PencilIcon'
import SwitchGasIcon from '../../images/icons/SwitchGasIcon'
import { RootState } from '../../store/store'
import { TypeTkoInfo } from './_types'

const ItemTkoInfo = ({ tko, handleChangeName }: { tko: TypeTkoInfo, handleChangeName: (tko: TypeTkoInfo) => void }) => {

    const { t } = useTranslation()

    const service_id = useSelector((state: RootState) => state.contract.service_id)



    const bgService = service_id === 1 ? 'bg-orange-500' : service_id === 2 ? 'bg-blue-600' : 'bg-gray-300'

    // const [open, setOpen] = useState(false);
    // const [modalValue, setModalValue] = useState(null);


    const ItemText = ({ title, text }: any) => (
        <div className='flex flex-col lap:flex-row items-baseline gap-[8px] lap:mr-0 min-w-[60px]'>
            <Typography variant='lead' className='text-[14px] tab:text-[16px] text-gray-700 font-[400] mb-[4px]'>
                {title}
            </Typography>
            <Typography variant='lead' className='text-[14px] tab:text-[16px] text-gray-800 font-medium'>
                {text}
            </Typography>
        </div>
    )

    return (
        <div className='flex flex-col items-start'>
            <div className='flex flex-row-reverse lap:flex-row items-center justify-between lap:justify-start w-full gap-[24px] lap:mb-[24px]'>
                <Button
                    color="white"
                    size='sm'
                    className={`w-[40px] h-[40px] flex items-center justify-center`}
                    onClick={() => handleChangeName(tko)}
                >
                    <PencilIcon />
                </Button>
                <div className='flex flex-col gap-[4px]'>
                    <Typography variant='lead' className='text-gray-700 font-[400] text-[14px] lap:text-[16px] '>
                        {t('consumption.tko_text_title')}
                    </Typography>
                    <Typography variant='lead' className='text-gray-800 font-medium text-[20px] lap:text-[24px]'>
                        {tko.name}
                    </Typography>
                </div>
            </div>
            <hr className='lap:hidden my-[24px] border-gray-100 w-full' />
            <div className='w-full flex items-center gap-[24px]'>
                <div className={`hidden lap:flex items-center justify-center ${bgService} rounded-full min-w-[40px] h-[40px] `}>
                    {
                        service_id === 1 &&
                        <LightBulbIcon />
                    }
                    {
                        service_id === 2 &&
                        <SwitchGasIcon className='h-[20px] w-[20px]' />
                    }
                </div>
                <div className='w-full flex flex-wrap justify-between lap:flex-col gap-[24px] lap:gap-[8px]'>
                    {
                        tko.eic &&
                        <ItemText title={t('consumption.eic')} text={tko.eic} />
                    }
                    {
                        tko.xcode &&
                        <ItemText title={t('consumption.xcode')} text={tko.xcode} />
                    }
                    {
                        tko.virtual_tko &&
                        <ItemText title={t('consumption.virtual_tko')} text={tko.virtual_tko} />
                    }
                    {
                        tko.class &&
                        <ItemText title={t('consumption.class')} text={tko.class} />
                    }
                    {
                        tko.group &&
                        <ItemText title={t('consumption.group')} text={t(`contract_page.group_${tko.group}`)} />
                    }
                    {
                        tko.osr_name &&
                        <ItemText title={t('consumption.osr_name')} text={tko.osr_name} />
                    }
                </div>
            </div>

            <hr className='lap:hidden my-[24px] border-gray-100 w-full' />
        </div>
    )
}

export default ItemTkoInfo
