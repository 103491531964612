

import { useState } from 'react';

import { visuallyHidden } from '@mui/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { Box, TableSortLabel } from '@mui/material';

import { Button, Menu, MenuHandler, MenuItem, MenuList, Typography } from '@material-tailwind/react';


import MoreDotsIcon from '../../images/icons/MoreDotsIcon';
import DownloadIcon from '../../images/icons/DownloadIcon';


import { useTranslation } from 'react-i18next';

import { isIterableArray, numWord, toFormatPrice, } from '../../utils/utils';
import { InvoiceHeadCell, InvoiceRowType } from './InvoicePage.types';
import { DOWNLOAD_ARCHIVE_LENGTH, OrderType } from '../tableHelpers/tableConfig';
import axios from 'axios';
import { baseApi } from '../../ConfigApi';
import TableToolbar from '../tableHelpers/TableToolbar';
import CopyButton from '../tableHelpers/buttons/CopyButton';
import SelectIcon from '../../images/icons/SelectIcon';
import { NavLink, useParams } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { errorToast } from '../../components/toasts/toasts';
import ModalViewPdf from './ModalViewPdf';
import DownloadBtn from '../tableHelpers/buttons/DownloadBtn';
import TableEmptyRows from '../tableHelpers/TableEmptyRows';
import InvoicesCards from './mobile/InvoicesCards';

type PropsType = {
    isLoadingTable: boolean,
    rows: InvoiceRowType[],
    visibleRows: InvoiceRowType[],
    orderBy: keyof InvoiceRowType,
    order: OrderType,
    service_id: any,
    handleRequestSort: any
}
const InvoiceTableDumb = (props: PropsType): JSX.Element => {

    const { param_contract_id } = useParams()
    const { t } = useTranslation()
    const {
        isLoadingTable,
        rows,
        visibleRows,
        orderBy,
        order,
        service_id,
        handleRequestSort,
        // handleDownloadFile,
        // handleDownloadSelected
    } = props

    // ______SERVICE COLORS

    const hoverRowService = service_id === 1 ? 'hover:!bg-orange-50' : service_id === 2 ? 'hover:!bg-blue-100' : 'hover:!bg-gray-200'
    const sortIconService = service_id === 1 ? 'svg-sort-orange' : service_id === 2 ? 'svg-sort-blue' : ''

    // array selected id's
    const [selected, setSelected] = useState<number[]>([]);


    // ______MODAL VIEW PDF
    const [formForViewPdf, setFormForViewPdf] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const handleOpen = (row: InvoiceRowType) => {
        const formSend = {
            number: row.number,
            balance_id: row.balance_id,
            invoice_type: row.invoice_type,
            version: row.version,
            contract_id: param_contract_id,
            filename: row.filename
        }
        setFormForViewPdf(formSend)
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false)
        setFormForViewPdf(null)
    };


    // ______CHECKBOXS

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const handleClickSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((item: any) => item.id);
            setSelected(newSelected)
        }
        else setSelected([])
    };

    const handleClickCheckbox = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected)
    };
    // _____


    function TableHeadComponent() {
        const createSortHandler = (property: keyof InvoiceRowType) => (event: React.MouseEvent<unknown>) => {
            handleRequestSort(event, property);
        };
        return (
            <TableHead className='table-head-border border-none font-sans'>
                <TableRow>
                    <TableCell className='font-sans' padding="checkbox" sx={{ paddingBottom: '0', border: 'none' }} >
                        <Checkbox
                            disableRipple={true}
                            className={`svg-checkbox-dark hover:bg-white`}
                            indeterminate={selected.length > 0 && selected.length < rows.length}
                            checked={rows.length > 0 && selected.length === rows.length}
                            onChange={handleClickSelectAll}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={'normal'}
                            sx={{ paddingBottom: '0px !important', paddingTop: '0px !important', border: 'none' }}
                            className={`!font-sans ${headCell.className}`}
                        >
                            {
                                headCell.sorting ?
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                        className={`${sortIconService} text-base !text-gray text-nowrap`}
                                    >
                                        {t(headCell.label)}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden} >
                                                <>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </>
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                    :
                                    <span className='text-base font-normal !text-gray-600 whitespace-nowrap'>
                                        {t(headCell.label)}
                                    </span>
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
    function TableRowsRender({ cells, row }: { cells: InvoiceHeadCell[], row: InvoiceRowType }) {
        return (
            <>
                {
                    cells.map((cell, index) => {
                        const key = cell.dataIndex
                        return (
                            <TableCell
                                key={cell.dataIndex}
                                align={cell.align}
                                sx={{
                                    border: 'none', lineHeight: '120%',
                                    borderTopRightRadius: index === cells.length - 1 ? 16 : 0,
                                    borderBottomRightRadius: index === cells.length - 1 ? 16 : 0
                                }}
                                padding={'normal'}
                                className='!font-sans !text-gray-700 !text-[16px]'
                            >
                                {
                                    cell.render ?
                                        cell.render(row[key], row)
                                        :
                                        row[key]
                                }
                            </TableCell>
                        )
                    })
                }
            </>
        )
    }

    // const getSubst = (str: string, separator: string): any => {
    //     const newText = str.replace(new RegExp(separator), `<span class='${textSearchService} rounded-[4px] p-[2px]'>` + separator + "</span>");
    //     return <>
    //         <span dangerouslySetInnerHTML={{ __html: newText }} />
    //     </>
    // }
    // const isSubst = (value: string) => (
    //     searchValue && value.replaceAll(' ', '').includes(searchValue.replaceAll(' ', '')) ?
    //         getSubst(value, searchValue)
    //         :
    //         value
    // )


    const headCells: InvoiceHeadCell[] = [
        {
            dataIndex: 'period', // какое поле в таблице
            id: 'period_sorter', // какое поле в сортировке
            align: 'left',
            sorting: true,
            label: 'invoices_page.period',
            className: 'min-w-[80px]',
            render: (item: any, record?: any) => (
                <span className='text-gray-700'>
                    {/* {isSubst(item)} */}
                    {item}
                </span>
            )
        },
        {
            dataIndex: 'number',
            id: 'number',
            sorting: true,
            align: 'left',
            label: 'invoices_page.number',
            className: 'min-w-[120px]',
            render: (item: any, record?: any) => (
                <span className='text-gray-700'>
                    {/* {isSubst(item)} */}
                    {item}
                </span>
            )
        },

        {
            dataIndex: 'invoice_type_table',
            id: 'invoice_type',
            sorting: true,
            align: 'left',
            label: 'invoices_page.invoice_type',
            className: 'min-w-[170px]',
            render: (item: any, record: any) => (
                <div className={`text-gray-700`}>
                    {/* {isSubst(t(`invoices_page.invoice_type_${item}`))} */}
                    {/* {t(`invoices_page.invoice_type_${item}`)} */}
                    {item}
                </div>
            )
        },
        {
            dataIndex: 'balance_id_table',
            id: 'balance_id',
            sorting: true,
            align: 'left',
            label: `invoices_page.balance_id`,
            className: 'min-w-[150px]',
            render: (item: any, record: any) => (
                <div className={`text-gray-700`}>
                    {/* {isSubst(t(`invoices_page.balance_${item}`))} */}
                    {/* {t(`balance_${item}`)} */}
                    {item}
                </div>
            )
        },
        {
            dataIndex: 'date_payment',
            id: 'date_payment_sorter',
            sorting: true,
            align: 'left',
            label: `invoices_page.date_payment`,
            className: 'min-auto',
            render: (item: any, record: any) => (
                <div className={`text-gray-700 whitespace-nowrap`}>
                    {/* {isSubst(item)} */}
                    {item}
                </div>
            )
        },
        {
            dataIndex: 'amount_with_nds',
            id: 'amount_with_nds',
            sorting: true,
            align: 'left',
            label: `invoices_page.amount_with_nds`,
            className: 'min-w-[150px]',
            render: (item: any, record: any) => (
                <span className={`text-[16px] font-bold text-gray-700`}>
                    {/* {isSubst(toFormatPrice(item))} */}
                    {toFormatPrice(item)}
                </span>
            )
        },
        {
            dataIndex: 'amount_for_payment',
            id: 'amount_for_payment',
            sorting: true,
            align: 'left',
            label: `invoices_page.amount_for_payment`,
            className: 'min-w-[150px]',
            render: (item: any, record: any) => (
                <span className={`text-[16px] font-bold text-gray-700`}>
                    {/* {isSubst(toFormatPrice(item))} */}
                    {toFormatPrice(item)}
                </span>
            )
        },
        {
            dataIndex: 'purpose',
            id: 'purpose',
            align: 'left',
            label: `invoices_page.purpose`,
            className: 'min-w-[200px] des:w-[50%]',
            render: (item: any, record: any) => (
                <div className={`text-gray-600 text-[14px]`}>
                    {/* {isSubst(item)} */}
                    {item}
                </div>
            )
        },
        {
            dataIndex: 'id',
            id: 'id',
            align: 'left',
            label: ` `,
            render: (item: any, record: any) => (
                <div className={`text-gray-600`}>
                    <div className='flex items-center gap-[16px]'>
                        <div className='flex items-center gap-[4px]'>
                            <CopyButton
                                copyText={record?.purpose || ''}
                            />
                            <DownloadBtn
                                icon={<DownloadIcon />}
                                tooltipText={t('invoices_page.download_file')}
                                record={record}
                                handleDownloadFile={handleDownloadFile}
                            />
                        </div>

                        <Menu placement="bottom-end">
                            <MenuHandler>
                                <Button
                                    color="white"
                                    size='sm'
                                    className='w-[40px] h-[40px] flex items-center justify-center'
                                    onClick={(event: any) => {
                                        event.stopPropagation()
                                    }}
                                >
                                    <MoreDotsIcon className='h-[16px] overflow-visible stroke-gray-700' />
                                </Button>
                            </MenuHandler>
                            <MenuList>
                                <MenuItem className='p-0'>
                                    <NavLink
                                        to={`/invoice-history/${record.id}`}
                                        className='block px-[12px] py-[9px] '
                                    >
                                        {t('invoices_page.payment_history')}
                                    </NavLink>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => handleOpen(record)}
                                >
                                    {t('invoices_page.view_file')}
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </div>
                </div>
            )
        },
    ];


    const handleDownloadFile = (row: InvoiceRowType, callback?: () => void) => {

        const formSend = {
            number: row.number,
            balance_id: row.balance_id,
            invoice_type: row.invoice_type,
            version: row.version,
            contract_id: param_contract_id,
            filename: row.filename
        }
        axios.post(`${baseApi}/api/invoice/export-file`, formSend, { responseType: 'blob' })
            .then((response: any) => {
                if (response.data.res === 'error') {
                    errorToast({
                        title: t('invoices_page.invoices_title'),
                        text: t('error_on_download')
                    })
                }
                else fileDownload(response.data, formSend.filename)
            })
            .catch(error => {
                console.error(error);
                errorToast({
                    title: t('invoices_page.invoices_title'),
                    text: t('error_on_download')
                })
            })
            .finally(() => {
                if (callback) callback()
            })
    }

    const downloadArchive = (arr: number[]) => {
        axios.post(`${baseApi}/api/invoice/export-files-zip`, {
            arr
        }, { responseType: 'blob' })
            .then((response: any) => {
                if (response.data.res === 'error') {
                    errorToast({
                        title: t('invoices_page.invoices_title'),
                        text: t('error_on_download')
                    })
                }
                else fileDownload(response.data, `Files.zip`)
            })
            .catch(error => {
                console.error(error);
                errorToast({
                    title: t('invoices_page.invoices_title'),
                    text: t('error_on_download')
                })
            })
    }

    const handleDownloadSelected = (selected: number[]) => {
        const rowsToDownload = selected.reduce((acc: any, id: number) => {
            const row = rows.find(item => item.id === id)
            acc.push(row)
            return acc
        }, [])

        if (rowsToDownload.length > DOWNLOAD_ARCHIVE_LENGTH) {
            downloadArchive(selected)
        }
        else rowsToDownload.map((row: InvoiceRowType) => handleDownloadFile(row))
    }



    return (
        <>
            <ModalViewPdf
                formSend={formForViewPdf}
                open={open}
                handleClose={handleClose}
            />
            <TableToolbar
                isOpen={selected?.length > 0}
            >
                <Typography
                    color="inherit"
                // component="div" 
                >
                    {t('selected')}
                    <span className='font-bold'> {selected.length}  {t(`invoices_page.doc_active_count_${numWord(selected.length)}`)}</span>
                </Typography>
                <Menu placement="top" >
                    <MenuHandler>
                        <Button color='white' className='w-[300px] px-[16px] rounded flex items-center justify-between'>
                            {t('action')}
                            <SelectIcon />
                        </Button>
                    </MenuHandler>
                    <MenuList className='w-[300px]'>
                        <MenuItem
                            className='p-[16px]'
                            onClick={() => {
                                handleDownloadSelected(selected)
                                setSelected([])
                            }}
                        >
                            {t('invoices_page.download_selected_documents')}
                        </MenuItem>
                    </MenuList>
                </Menu>
            </TableToolbar>

            <InvoicesCards
                visibleRows={visibleRows}
                isLoadingTable={isLoadingTable}
                handleDownloadFile={handleDownloadFile}
            />

            <TableContainer className='hidden tab:block'>
                <Table
                    sx={{
                        minWidth: 750,
                        borderCollapse: 'separate',
                        borderSpacing: '0 16px',
                    }}
                    size={'medium'}
                >
                    <TableHeadComponent />
                    <TableBody>
                        {
                            isIterableArray(visibleRows) ?
                                visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    return (
                                        <TableRow
                                            key={row.id}
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            selected={isItemSelected}
                                            className={`  
                                                table-row-border
                                                service_id_${service_id}
                                                ${hoverRowService}
                                            `}
                                        >
                                            <TableCell
                                                padding="checkbox"
                                                sx={{
                                                    border: 'none', lineHeight: '120%',
                                                    borderTopLeftRadius: 16,
                                                    borderBottomLeftRadius: 16,

                                                }}
                                                onClick={(event: any) => {
                                                    event.stopPropagation()
                                                    handleClickCheckbox(event, row.id)
                                                }}
                                            >
                                                <div className='flex items-center min-h-[64px]'>
                                                    <Checkbox
                                                        disableRipple={true}
                                                        color="default"
                                                        className='svg-checkbox-dark hover:!bg-white'
                                                        checked={isItemSelected}
                                                    />
                                                </div>
                                            </TableCell>
                                            <TableRowsRender cells={headCells} row={row} />
                                        </TableRow>
                                    );
                                })
                                :
                                <TableEmptyRows isLoadingTable={isLoadingTable} />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default InvoiceTableDumb
