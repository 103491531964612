import React, { useEffect, useState } from 'react'
import { isIterableArray } from '../../../utils/utils'
import { useWatch } from 'react-hook-form'
import MatrixAmountButtons from './MatrixAmountButtons'

const MatrixDays = ({
    data, register, setValue, DownloadExcelButton, isEditAccess, saveMatrixDays, amount, isLoadingSave, control, handleNavigateBack
}: {
    data: number[],
    register?: any,
    setValue?: any,
    DownloadExcelButton?: any,
    saveMatrixDays?: any,
    isLoadingSave?: any,
    amount: number | boolean,
    isEditAccess: boolean,
    control: any,
    handleNavigateBack: any
}) => {

    const [computedAmount, setComputedAmount] = useState(amount)

    const checkboxIds = useWatch({ control, name: 'data' });

    useEffect(() => {
        if (isIterableArray(checkboxIds)) {
            const sum = checkboxIds.reduce((acc: number, item: number | string) => {
                acc += Number(item)
                return acc
            }, 0)
            setComputedAmount(sum)
        }
    }, [checkboxIds])


    return (
        <>
            <MatrixAmountButtons
                isLoadingSave={isLoadingSave}
                DownloadExcelButton={DownloadExcelButton}
                isEditAccess={isEditAccess}
                handleSave={saveMatrixDays}
                amount={computedAmount}
                handleNavigateBack={handleNavigateBack}
            />
            <div className='rounded-[16px] bg-white border-2 border-gray-100 p-[24px] grid grid-cols-3 mob:grid-cols-4 tab:flex item-center flex-wrap gap-[8px] '>
                {
                    isIterableArray(data) &&
                    data.map((i: number, key: number) => (
                        <div key={key} className={`flex flex-col items-center gap-[4px] mb-[8px]`}>
                            <span className='text-[14px] text-gray-500 font-medium '>{key + 1}</span>
                            {
                                (register && setValue) ?
                                    <input
                                        {...register(`data.${key}`)}
                                        type='number'
                                        control={false}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const n = parseFloat(e.target.value)
                                            setValue(`data.${key}`, n || 0)
                                        }
                                        }
                                        className={`border-2 border-gray-100 rounded w-full tab:w-[95px] h-[32px] tab:h-[48px] px-[4px] text-center text-gray-800 text-[14px] font-bold
                                            ${key % 2 !== 0 && 'bg-gray-100'}
                                            hover:bg-gray-50 hover:border-gray-300 outline-gray-400
                                            focus:border-gray-200 focus-visible:!border-gray-100 transition-all
                                        `}
                                    // value={i}
                                    />
                                    :
                                    <input
                                        disabled
                                        type='number'
                                        className={`border-2 border-gray-100 rounded w-full tab:w-[95px] h-[32px] tab:h-[48px] px-[4px] text-center text-gray-800 text-[14px] font-bold
                                            ${key % 2 !== 0 && 'bg-gray-100'} 
                                        `}
                                        value={i}
                                    />
                            }
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default MatrixDays