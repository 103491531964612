import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import bgAuth from '../../../images/auth-bg.jpg'
import { Badge, Button, Checkbox, Typography } from '@material-tailwind/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import ElectroConnection from './ElectroConnection'
import GasConnection from './GasConnection'
import { useState } from 'react'
import ModalAddition from './ModalAddition'
import { NavLink, useNavigate } from 'react-router-dom'
import ModalAddFiles from './ModalAddFiles'
import { isIterableArray } from '../../../utils/utils'
import axios from 'axios'
import { baseApi } from '../../../ConfigApi'
import { errorToast, successToast } from '../../toasts/toasts'

type FormType = {
  name: string,
  edrpou: string,
  contact_type_1: number,
  contact_type_2: number,
  contact_type_3: number,
  phone: string,
  email: string,
  service_1: boolean,
  service_2: boolean,
  group_1: boolean,
  group_2: boolean,
  group_3: boolean,
  group_4: boolean,
  class_1: boolean,
  class_2: boolean,
  class_3: boolean,
  gas_consumption_1: boolean,
  gas_consumption_2: boolean,
  gas_consumption_3: boolean,
  gas_consumption_4: boolean,
  addition_service_1: boolean,
  addition_service_2: boolean,
  addition_service_3: boolean,
  addition_service_4: boolean,
}
const PageNewConnection = () => {

  const { t } = useTranslation()

  const navigate = useNavigate()

  const {

    handleSubmit,
    register,
    control,
  } = useForm<FormType>({
    shouldUseNativeValidation: true,
    // defaultValues: {},
  })

  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const [filesListForSend, setFilesListForSend] = useState<any[]>([]);
  const [isModalFile, setIsModalFile] = useState(false);
  const [isModalAdd, setIsModalAdd] = useState(false)

  const openModalAdd = () => {
    setIsModalAdd(true)
  }
  const closeModalAdd = () => {
    setIsModalAdd(false)
  }
  const openModalFile = () => {
    setIsModalFile(true)
  }
  const closeModalFile = () => {
    setIsModalFile(false)
  }

  const onSubmit: SubmitHandler<FormType> = (data) => {
    console.log(data)
    console.log(filesListForSend)
    if (data.service_1 || data.service_2) {
      setIsLoadingSave(true)
      const formSend = {
        "type": 1,
        "contract_id": 0,
        files: filesListForSend,
        ...data
      }
      axios.post(`${baseApi}/api/cabinet/profile-requestconnect-create`, formSend)
        .then((response: any) => {
          if (response.data.res === 'Error') {
            errorToast({
              title: t('new_connection.new_connection_title'),
              text: t('new_connection.error_on_request')
            })
          }
          else {
            successToast({
              title: t('new_connection.new_connection_title'),
              text: t('new_connection.success_on_request')
            })
            navigate("/auth")
          }
        })
        .catch(error => {
          console.error(error);
          errorToast({
            title: t('new_connection.new_connection_title'),
            text: t('new_connection.error_on_request')
          })
        })
        .finally(() => {
          setIsLoadingSave(false)
        })
    }
    else {
      errorToast({
        title: t('new_connection.no_selected_service')
      })
    }
  }

  return (

    <div>
      <Helmet>
        <title>{t('crumb_main')}</title>
      </Helmet>
      <ModalAddition
        open={isModalAdd}
        handleClose={closeModalAdd}
        register={register}
      />
      <ModalAddFiles
        open={isModalFile}
        handleClose={closeModalFile}
        setFilesListForSend={setFilesListForSend}
      />
      <div className='fixed t-0 r-0 b-0 l-0  min-h-[100vh] w-screen'
        style={{
          backgroundImage: `url(${bgAuth})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          zIndex: -1
        }}
      />
      <div className='lap:p-[16px] min-h-[100vh] lap:w-screen flex flex-wrap lap:flex-nowrap items-center' >
        <div className='p-[64px] lap:p-[96px] flex flex-col w-full lap:grow-[1] lap:w-[25%]  justify-center overflow-hidden text-balance'>
          <Typography variant='lead' className='text-[28px] des:text-[40px] text-gray-800 lap:mb-[48px] text-center lap:text-center' >
            {t('new_connection.new_connection_title_1')}
            <br />
            <span className='block font-bold'>{t('new_connection.new_connection_title_2')}</span>
          </Typography>
        </div>
        <div className='p-[16px] mob:p-[32px] tab:p-[48px] w-full lap:grow-[3] lap:w-[75%]'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col lap:flex-row gap-[24px]">
              <div className='flex grow w-full flex-col'>
                <Typography variant='lead' className='text-gray-800 text-[18px] font-bold mb-[24px]' >
                  {t('new_connection.step_1')}
                </Typography>
                <div className='h-full bg-white rounded-[16px] lap:rounded-[24px] p-[24px] tab:p-[32px] lap:p-[48px] flex flex-col gap-[24px] lap:gap-[32px]'>

                  {/* name */}
                  <div className='flex flex-col gap-[12px] lap:gap-[16px]'>
                    <label htmlFor='name' className='text-[18px] lap:text-[24px] text-gray-800'>
                      {t('new_connection.name')} <span className='text-red-500'>*</span>
                    </label>
                    <input
                      placeholder={t('new_connection.name_placeholder')}
                      id='name'
                      {...register(`name`, {
                        required: t('new_connection.required_input')
                      })}
                      type='text'
                      className={`bg-white border-2 border-gray-200 rounded w-full h-[48px] px-[16px] text-gray-800 text-[18px]
                      hover:bg-gray-50 hover:border-gray-300 
                      focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                    `}
                    />
                  </div>

                  {/* edrpou */}
                  <div className='flex flex-col gap-[12px] lap:gap-[16px]'>
                    <label htmlFor='edrpou' className='text-[18px] lap:text-[24px] text-gray-800'>
                      {t('new_connection.edrpou')}
                    </label>
                    <input
                      placeholder={t('new_connection.edrpou_placeholder')}
                      id='edrpou'
                      {...register(`edrpou`)}
                      type='number'
                      className={`bg-white border-2 border-gray-200 rounded w-full h-[48px] px-[16px] text-gray-800 text-[18px]
                      hover:bg-gray-50 hover:border-gray-300 
                      focus:border-gray-100 focus-visible:!border-gray-200 transition-all
                    `}
                    />
                  </div>

                  {/* connect type */}
                  <div className="grid mob:grid-cols-2 mob:gap-[24px]">
                    <Typography variant='lead' className='text-gray-800 text-[18px] font-normal mb-[12px] mob:mb-[24px]' >
                      {t('new_connection.contact_type')}
                    </Typography>
                    <div className='flex flex-col gap-[8px]'>
                      <Checkbox
                        {...register(`contact_type_1`)}
                        className='rounded-full checked:bg-white border-2'
                        icon={<span className='block w-[10px] h-[10px] bg-black rounded-full ' />}
                        label={t('new_connection.contact_type_1')}
                      />
                      <Checkbox
                        {...register(`contact_type_2`)}
                        className='rounded-full checked:bg-white border-2'
                        icon={<span className='block w-[10px] h-[10px] bg-black rounded-full ' />}
                        label={t('new_connection.contact_type_2')}
                      />
                      <Checkbox
                        {...register(`contact_type_3`)}
                        className='rounded-full checked:bg-white border-2'
                        icon={<span className='block w-[10px] h-[10px] bg-black rounded-full ' />}
                        label={t('new_connection.contact_type_3')}
                      />
                    </div>
                  </div>


                  {/* PHONE */}
                  <div className='flex flex-col gap-[12px] lap:gap-[16px]'>
                    <label htmlFor='phone' className='text-[18px] lap:text-[24px] text-gray-800'>
                      {t('new_connection.phone')} <span className='text-red-500'>*</span>
                    </label>
                    <input
                      placeholder={t('new_connection.phone_placeholder')}
                      id='phone'
                      {...register(`phone`, {
                        required: t('new_connection.required_input')
                      })}
                      type='text'
                      className={`bg-white border-2 border-gray-200 rounded w-full h-[48px] px-[16px] text-gray-800 text-[18px]
                      hover:bg-gray-50 hover:border-gray-300 
                      focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                    `}
                    />
                  </div>


                  {/* email */}
                  <div className='flex flex-col gap-[12px] lap:gap-[16px]'>
                    <label htmlFor='email' className='text-[18px] lap:text-[24px] text-gray-800'>
                      {t('new_connection.email')}
                    </label>
                    <input
                      placeholder={t('new_connection.email_placeholder')}
                      id='email'
                      {...register(`email`)}
                      type='text'
                      className={`bg-white border-2 border-gray-200 rounded w-full h-[48px] px-[16px] text-gray-800 text-[18px]
                      hover:bg-gray-50 hover:border-gray-300 
                      focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                    `}
                    />
                  </div>


                </div>
              </div>
              <div className='flex grow w-full flex-col'>
                <Typography variant='lead' className='text-gray-800 text-[18px] font-bold mb-[24px]' >
                  {t('new_connection.step_2')}
                </Typography>
                <div className='h-full bg-white rounded-[16px] lap:rounded-[24px] p-[24px] tab:p-[32px] lap:p-[48px] flex flex-col gap-[24px] lap:gap-[32px]'>
                  <Typography variant='lead' className='text-[18px] lap:text-[24px] text-gray-800'>
                    {t('new_connection.step_2_subtitle')} <span className='text-red-500'>*</span>
                  </Typography>

                  <div className='grid mob:grid-cols-2 gap-[24px] mob:gap-0'>
                    <ElectroConnection control={control} register={register} />
                    <GasConnection control={control} register={register} />
                  </div>

                  <div className='mt-auto flex flex-wrap items-center justify-end mob:justify-start gap-[8px]'>
                    <Typography variant='lead' className='text-[18px] text-gray-800 font-normal'>
                      {t('new_connection.addition_title')}
                    </Typography>
                    <Button color='white' className='px-[16px] py-[4px] rounded-[6px] bg-gray-50' onClick={openModalAdd}>
                      {t('new_connection.addition_btn')}
                    </Button>
                    {
                      isIterableArray(filesListForSend)
                        ?
                        <Badge content={filesListForSend.length}>
                          <Button color='white' className='px-[16px] py-[4px] rounded-[6px] bg-gray-50 text-[14px] tab:text-[16px]' onClick={openModalFile}>
                            {t('new_connection.addition_upload')}
                          </Button>
                        </Badge>
                        :
                        <Button color='white' className='px-[16px] py-[4px] rounded-[6px] bg-gray-50 text-[14px] tab:text-[16px]' onClick={openModalFile}>
                          {t('new_connection.addition_upload')}
                        </Button>
                    }

                  </div>

                </div>
              </div>
            </div>
            <div className="flex flex-col lap:flex-row items-center gap-[24px] mt-[24px] tab:mt-[48px] des:mt-[96px] ">
              <div className='flex grow w-full flex-col'>
                <Checkbox
                  label={
                    <div className='text-[14px] lap:text-[16px]'>
                      {t('new_connection.confirm_text')} <NavLink to={'#'} className={'underline font-bold'}>{t('new_connection.confirm_link')}</NavLink>
                    </div>
                  }
                />
              </div>
              <div className='flex flex-col-reverse mob:flex-row mob:items-center gap-[8px] grow w-full'>
                <Button
                  color='white'
                  className='border-gray-100 text-[14px] lap:text-[20px] py-[10px] tab:py-[20px] grow'
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  {t('new_connection.cancel_btn')}
                </Button>
                <Button
                  loading={isLoadingSave}
                  color='white'
                  type='submit'
                  className='border-blue-200 bg-blue-100 hover:bg-blue-200 text-[14px] lap:text-[20px] py-[10px] tab:py-[20px] grow'
                >
                  {t('new_connection.confirm_btn')}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default PageNewConnection