import { Typography } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'

const TkoInfoCard = ({ tko }: { tko: any }) => {

    const { t } = useTranslation()

    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const serviceBorder = service_id === 1 ? 'border-orange-500 border-[2px]' : service_id === 2 ? 'border-blue-600 border-[2px]' : 'border-gray-100 border-2'

    const ItemText = ({ title, text }: any) => (
        <div className="flex tab:items-start tab:flex-col justify-between w-full tab:w-auto gap-[4px]">
            <Typography variant='lead' className='text-gray-700 font-[400] text-[14px] lap:text-[16px]'>
                {title}
            </Typography>
            <Typography variant='lead' className='text-gray-800 font-medium text-[14px] lap:text-[20px] des:text-[24px]'>
                {text}
            </Typography>
        </div>
    )

    return (
        <div className={`${serviceBorder} rounded-[16px] lap:rounded-[24px]  p-[16px] tab:p-[24px] lap:p-[48px] gap-[8px] flex flex-wrap flex-col tab:flex-row items-start justify-between `}>
            {
                tko.month && tko.year &&
                <ItemText title={t('consumption.period')} text={t(`month_${tko.month}`) + ' ' + tko.year} />
            }
            {
                tko.name &&
                <ItemText title={t('consumption.tko_text_title')} text={tko.name} />
            }
            <hr className='tab:hidden my-[16px] border-gray-100 w-full' />
            {
                tko.virtual_tko &&
                <>
                    <ItemText title={t('consumption.virtual_tko')} text={tko.virtual_tko} />
                    {!tko.xcode && <hr className='tab:hidden my-[16px] border-gray-100 w-full' />}
                </>
            }
            {
                tko.xcode &&
                <>
                    <ItemText title={t('consumption.xcode')} text={tko.xcode} />
                    <hr className='tab:hidden my-[16px] border-gray-100 w-full' />
                </>
            }
            {
                tko.eic &&
                <ItemText title={t('consumption.eic')} text={tko.eic} />
            }
            {
                tko.class &&
                <ItemText title={t('consumption.class')} text={tko.class} />
            }
            {!tko?.virtual_tko && !tko?.xcode && <hr className='tab:hidden my-[16px] border-gray-100 w-full' />}
            {
                tko.group &&
                <ItemText title={t('consumption.group')} text={tko.group ? t(`contract_page.group_${tko.group}`) : ''} />
            }
            {
                tko.osr_name &&
                <ItemText title={t('consumption.osr_name')} text={tko.osr_name} />
            }
        </div>
    )
}

export default TkoInfoCard
