import { Typography, Accordion, ListItem, AccordionHeader, AccordionBody } from '@material-tailwind/react'
import { placement } from '@material-tailwind/react/types/components/menu';
import { t } from 'i18next';
import { useState } from 'react'
import { useController } from 'react-hook-form';
import { isIterableArray } from '../../../utils/utils';
import { DateSelectType } from '../../contract/ContractPage.types';
import AirDatepickerReact from './AirPicker';
import ArrowAccordionIcon from '../../../images/icons/ArrowAccordionIcon';

export type CalendarDaysType = {
    active: boolean,
    day: string
}
type propTypes = {
    placement?: placement,
    control: any,
    fieldName: string,
    defaultValue: any,
    label: string,
    datesData: DateSelectType,
    handleCloseFilter: any,
    closeDrawer: any,
    isOpenDefault?: boolean
}
const FilterPeriodMobile = (props: propTypes) => {

    const {
        control,
        fieldName,
        defaultValue,
        label,
        handleCloseFilter,
        closeDrawer,
        isOpenDefault = true
    } = props

    const {
        field: { value, onChange }
    } = useController({
        name: fieldName,
        control,
        defaultValue: defaultValue
    });


    const clearFilter = () => {
        onChange([])
    }
    const confirmFilter = () => {
        handleCloseFilter()
        closeDrawer()
    }


    const [open, setOpen] = useState<boolean>(isOpenDefault);
    const toggleAccordion = () => setOpen(prev => !prev);

    return (
        <Accordion
            open={open}
            icon={<div className={`${open ? '' : 'rotate-180'}`}><ArrowAccordionIcon /></div>}
            className='tab:mb-[24px]'
        >
            <ListItem className='p-0'>
                <AccordionHeader
                    className='bg-white border-2 border-gray-100 rounded p-[16px]'
                    onClick={toggleAccordion}
                >
                    <div className="flex items-start justify-between">
                        <div className='flex flex-col gap-[0px]'>
                            <Typography variant="lead" className='text-[14px] text-gray-800 font-bold' >
                                {label}
                            </Typography>
                        </div>
                    </div>
                </AccordionHeader>
            </ListItem>
            <AccordionBody className='bg-white border-2 border-gray-100 rounded p-0 mt-[8px] tab:mt-[16px]' >
                <div className="p-[12px_16px_0_16px] flex items-center justify-between">
                    <div className='flex flex-col gap-[0px]'>
                        <Typography variant="lead" className='text-[14px] font-medium h-[24px]' >
                            {isIterableArray(value) && value?.length === 2 ?
                                <span className='whitespace-nowrap text-gray-700 text-[12px] font-medium'>
                                    {t('filters.from')} {value[0]}
                                    {' - '}
                                    {t('filters.to')}  {value[1]}
                                </span>
                                :
                                <span className='text-gray-500 text-[12px]'>{t('filters.period_select_placeholder')}</span>
                            }
                        </Typography>
                    </div>
                    <div>
                        {
                            isIterableArray(value) &&
                            <div className='text-[12px] font-medium text-gray-600 hover:text-gray-500 transition-color easy duration-200 cursor-pointer'
                                onClick={clearFilter}
                            >
                                {t('filters.clear')}
                            </div>
                        }
                    </div>
                </div>
                <div className='p-[8px]'>
                    <AirDatepickerReact onChange={onChange} selectedDates={value} />
                </div>
            </AccordionBody>
        </Accordion>
    )
}

export default FilterPeriodMobile
