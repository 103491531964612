import { Button } from '@material-tailwind/react'
import { Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import EyeIcon from '../../images/icons/EyeIcon'
import { RootState } from '../../store/store'
import { isIterableArray, toStringPrice } from '../../utils/utils'
import {  TypeTkoPeriodItemGas } from './_types'

const EMPTY_DATA = '---'

const GasPeriodsTkos = ({ data, isFetching }: {
    data: TypeTkoPeriodItemGas[],
    isFetching: boolean,
}) => {

    const { param_contract_id } = useParams()

    const { t } = useTranslation()

    const { pathname, search } = useLocation()

    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const getHrefViewAll = (tkos: any, type: 'declared-gas' | 'declared-power' | 'factual-gas'): any => {
        const tkosStr = isIterableArray(tkos) ? tkos.join(',') : false
        if (tkosStr) {
            const href = `/consumptions/${param_contract_id}/${type}/view-all/` + '?&tkos=' + tkosStr
            return href
        }
        return null
    }

    const getFactParam = (fact: any) => {
        let hrefParams = ''
        const arr_fact = isIterableArray(fact.arr_fact) ? fact.arr_fact.join(',') : false
        const arr_gas = isIterableArray(fact.arr_gas) ? fact.arr_gas.join(',') : false
        const arr_power = isIterableArray(fact.arr_power) ? fact.arr_power.join(',') : false
        if (arr_fact) {
            hrefParams += '?&arr_fact=' + arr_fact
        }
        if (arr_gas) {
            hrefParams += '?&arr_gas=' + arr_gas
        }
        if (arr_power) {
            hrefParams += '?&arr_power=' + arr_power
        }
        return hrefParams
    }

    return (
        <div>
            {
                isIterableArray(data) &&
                <div className='flex flex-col gap-[16px] lap:gap-[32px]'>
                    <div className='grid grid-cols-[20%_26%_27%_27%] tab:grid-cols-4 gap-[15px] text-gray-600'>
                        <div className='px-[8px] lap:px-[16px]'>{t('consumption.period')}</div>
                        <div className='px-[8px] lap:px-[16px]'>{t('consumption.declared_gas')}</div>
                        <div className='px-[8px] lap:px-[16px]'>{t('consumption.declared_power')}</div>
                        <div className='px-[8px] lap:px-[16px]'>{t('consumption.fact_gas')}</div>
                    </div>
                    {
                        data.map((item: any, key: number) => (
                            <div key={key} className='grid grid-cols-[20%_26%_27%_27%] tab:grid-cols-4 text-[14px] tab:text-[16px] lap:text-[18px] gap-[15px]'>
                                {
                                    isFetching ?
                                        <>
                                            <Skeleton className='max-w-[120px] lap:mx-[16px]' />
                                            <Skeleton className='max-w-[120px] lap:mx-[16px]' />
                                            <Skeleton className='max-w-[120px] lap:mx-[16px]' />
                                            <Skeleton className='max-w-[120px] lap:mx-[16px]' />
                                        </>
                                        :
                                        <>
                                            <div className='leading-[2] px-[8px] lap:px-[16px] text-[14px] tab:text-[16px]'>{item.period}</div>

                                            {/* declared_gas */}
                                            <div className='flex items-center gap-[16px] font-bold'>
                                                {
                                                    typeof item.declared_gas.amount === 'number' ?
                                                        <div className={`
                                                            flex items-center justify-between w-[100%] py-[4px]  px-[8px] lap:px-[16px] rounded transition-all
                                                        `}>
                                                            <span className='leading-[2] text-[14px] tab:text-[16px]'>
                                                                {toStringPrice(item.declared_gas.amount)}
                                                            </span>
                                                            {
                                                                item.declared_gas.id &&
                                                                <NavLink
                                                                    state={{
                                                                        pathname, search
                                                                    }}
                                                                    to={`/consumptions/${param_contract_id}/declared-gas/view/${item.declared_gas.id}`}
                                                                >
                                                                    <Button
                                                                        color="white"
                                                                        size='sm'
                                                                        className={`w-[32px] h-[32px] flex items-center justify-center`}
                                                                    >
                                                                        <EyeIcon />
                                                                    </Button>
                                                                </NavLink>
                                                            }

                                                            {
                                                                isIterableArray(item.declared_gas.arr_gas) &&
                                                                <NavLink
                                                                    state={{
                                                                        pathname, search
                                                                    }}
                                                                    to={getHrefViewAll(item.declared_gas.arr_gas, 'declared-gas')}>
                                                                    <Button
                                                                        color="white"
                                                                        size='sm'
                                                                        className={`w-[32px] h-[32px] flex items-center justify-center`}
                                                                    >
                                                                        <EyeIcon />
                                                                    </Button>
                                                                </NavLink>
                                                            }
                                                        </div>
                                                        :
                                                        EMPTY_DATA
                                                }
                                            </div>

                                            {/* declared_power */}
                                            <div className='flex items-center gap-[16px] font-bold'>
                                                {
                                                    typeof item.declared_gas.amount === 'number' ?
                                                        <div className={`
                                                            flex items-center justify-between w-[100%] py-[4px]  px-[8px] lap:px-[16px] rounded transition-all
                                                        `}>
                                                            <span className='leading-[2]  text-[14px] tab:text-[16px]'>
                                                                {toStringPrice(item.declared_power.amount)}
                                                            </span>
                                                            {
                                                                item.declared_power.id &&
                                                                <NavLink
                                                                    state={{
                                                                        pathname, search
                                                                    }}
                                                                    to={`/consumptions/${param_contract_id}/declared-power/view/${item.declared_power.id}`}
                                                                >
                                                                    <Button
                                                                        color="white"
                                                                        size='sm'
                                                                        className={`w-[32px] h-[32px] flex items-center justify-center`}
                                                                    >
                                                                        <EyeIcon />
                                                                    </Button>
                                                                </NavLink>
                                                            }

                                                            {
                                                                isIterableArray(item.declared_power.arr_power) &&
                                                                <NavLink
                                                                    state={{
                                                                        pathname, search
                                                                    }}
                                                                    to={getHrefViewAll(item.declared_power.arr_power, 'declared-power')}>
                                                                    <Button
                                                                        color="white"
                                                                        size='sm'
                                                                        className={`w-[32px] h-[32px] flex items-center justify-center`}
                                                                    >
                                                                        <EyeIcon />
                                                                    </Button>
                                                                </NavLink>
                                                            }
                                                        </div>
                                                        :
                                                        EMPTY_DATA
                                                }
                                            </div>



                                            {/* fact */}
                                            <div className='flex items-center gap-[16px] font-bold'>
                                                {
                                                    typeof item.fact.amount === 'number' ?
                                                        <div className={`
                                                            flex items-center justify-between w-[100%] py-[4px]  px-[8px] lap:px-[16px] rounded transition-all
                                                        `}>
                                                            <span className='leading-[2] text-[14px] tab:text-[16px]'>{toStringPrice(item.fact.amount)}</span>

                                                            {
                                                                item.fact.id &&
                                                                <NavLink
                                                                    state={{
                                                                        pathname, search
                                                                    }}
                                                                    to={`/consumptions/${param_contract_id}/factual-gas/view/${item.fact.id}${getFactParam(item.fact)}`}
                                                                >
                                                                    <Button
                                                                        color="white"
                                                                        size='sm'
                                                                        className={`w-[32px] h-[32px] flex items-center justify-center`}
                                                                    >
                                                                        <EyeIcon />
                                                                    </Button>
                                                                </NavLink>
                                                            }
                                                            {
                                                                isIterableArray(item.fact.arr_fact) &&
                                                                <NavLink
                                                                    state={{
                                                                        pathname, search
                                                                    }}
                                                                    to={`/consumptions/${param_contract_id}/factual-gas/view-all${getFactParam(item.fact)}`}
                                                                // to={getHrefViewAll(item.fact.arr_fact, 'factual-gas')}
                                                                >
                                                                    <Button
                                                                        color="white"
                                                                        size='sm'
                                                                        className={`w-[32px] h-[32px] flex items-center justify-center`}
                                                                    >
                                                                        <EyeIcon />
                                                                    </Button>
                                                                </NavLink>
                                                            }
                                                            {/* <div className='flex items-center gap-[8px]'>
                                                                {
                                                                    item.show && !item.isClose &&
                                                                    <>
                                                                        <NavLink
                                                                            state={{
                                                                                pathname, search
                                                                            }}
                                                                            to={`/consumptions/${param_contract_id}/view-factual/${item.fact.id}`}>
                                                                            <Button
                                                                                color="white"
                                                                                size='sm'
                                                                                className={`w-[32px] h-[32px] flex items-center justify-center`}
                                                                            >
                                                                                <EyeIcon />
                                                                            </Button>
                                                                        </NavLink>
                                                                    </>
                                                                }
                                                                {
                                                                    isIterableArray(item.fact.arr_fact_id) &&
                                                                    <NavLink
                                                                        state={{
                                                                            pathname, search
                                                                        }}
                                                                        to={getHrefViewAll(item.fact.arr_fact_id, 'fact')}>
                                                                        <Button
                                                                            color="white"
                                                                            size='sm'
                                                                            className={`w-[32px] h-[32px] flex items-center justify-center`}
                                                                        >
                                                                            <EyeIcon />
                                                                        </Button>
                                                                    </NavLink>
                                                                }
                                                            </div> */}
                                                        </div>
                                                        :
                                                        EMPTY_DATA
                                                }
                                            </div>
                                        </>}
                            </div>
                        ))
                    }
                </div>
            }
        </div >
    )
}

export default GasPeriodsTkos