import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCaViewPeriodQuery } from '../../store/auth/authService';
import { setContractId, setServiceId } from '../../store/service/contractSlice';
import { AppDispatch } from '../../store/store';
import Calendar from './calendar/Calendar';
import Tiles from './tiles/Tiles';
import { Typography } from '@material-tailwind/react';
import { ActiveDateType, DateSelectType } from './ContractPage.types';
import ContractPageBlocks from './ContractPageBlocks';
import DateSelect from './DateSelect';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import BannerNewConnection from '../../components/newConnection/BannerNewConnection';
import PageLayout from '../../layouts/pagesLayouts/PageLayout';

const ContractPage = () => {

    const defaultActiveDate = {
        month: moment().format('MM'),
        year: Number(moment().format('YYYY'))
    }
    const { t } = useTranslation()

    const { param_contract_id } = useParams()

    const [activeDate, setActiveDate] = useState<ActiveDateType>(defaultActiveDate);


    const { data, error, isLoading, isFetching } = useCaViewPeriodQuery({
        contract_id: Number(param_contract_id),
        ...activeDate
    })

    const [dateSelect, setDateSelect] = useState<DateSelectType | null>(null);


    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(setContractId(Number(param_contract_id)))
    }, [param_contract_id]);

    useEffect(() => {
        if (data?.service_id) dispatch(setServiceId(Number(data.service_id)))
    }, [data]);


    if (data?.accounting) console.log("DATA", data?.accounting)

    return (
        // p-[48px]
        <>
            <PageLayout>
                <div className={`service_id_${data?.service_id}`}>
                    <Helmet>
                        <title>
                            {t('menu.contract')}
                        </title>
                    </Helmet>

                    {/* <div className='min-h-[calc(100vh-140px)]'> */}

                    <div id='top' className='grid lap:grid-cols-2 lap:grid-rows-1  gap-[96px] tab:gap-[48px] des:gap-[96px] lap:pt-[48px] '>
                        <Tiles
                            data={data?.accounting}
                            month={activeDate.month}  //data?.month || 
                            isFetching={isFetching}
                        />
                        <div className='flex flex-col'>
                            <div className='flex items-center justify-between mb-[16px] tab:mb-[32px]'>
                                <Typography variant='h4' className='font-medium text-gray-800'>
                                    {t('contract_page.calendar_title')}
                                </Typography>

                                <DateSelect
                                    activeDate={activeDate}
                                    setActiveDate={setActiveDate}
                                    data={dateSelect}
                                />
                            </div>
                            <Calendar activeDate={activeDate} data={data?.calendar} isFetching={isFetching} />
                        </div>
                    </div>

                    <ContractPageBlocks setDateSelect={setDateSelect} />

                </div>
            </PageLayout>
            <BannerNewConnection />
        </>
    )
}

export default ContractPage
