import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../store/store';
import { useViewDeclaredQuery } from '../../../store/auth/authService';
import { isIterableArray, toStringPrice } from '../../../utils/utils';
import PageLayout from '../../../layouts/pagesLayouts/PageLayout';
import { Spinner, Typography } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import OverlayUnTouchable from '../../../layouts/pagesLayouts/OverlayUnTouchable';
import { setContractId, setServiceId } from '../../../store/service/contractSlice';
import TkosDeclared from './TkosDeclared';
import Filters from './Filters';
import { SubmitHandler, useForm } from 'react-hook-form'
import SkeletonDeclared from './SkeletonDeclared';
import AmountTitle from '../AmountTitle';
import axios from 'axios'
import { baseApi } from '../../../ConfigApi'
import { errorToast, successToast } from '../../../components/toasts/toasts'
import moment from 'moment';
import { Helmet } from 'react-helmet';
import MobileAmountTitle from '../MobileAmountTitle';

const defaulPeriodFrom = '05-2023'
const defaulPeriodTo = '07-2023'

export const setFormData = (data: any) => isIterableArray(data) ? data.reduce((acc: any, item: any) => {
    if (isIterableArray(item.data)) {
        item.data.map((tko: any) => {
            acc = {
                ...acc,
                ... { [`${item.tko.id}-${tko.month}-${tko.year}`]: typeof tko.declared?.amount === 'number' ? tko.declared?.amount : '' }
            }
        })
    }
    return acc
}, {})
    :
    []
type FilterFormType = {
    contract_id: string,
    periodFrom: string,
    periodTo: string,
    array_tko_id: number[]
}
const CreateDeclaredPage = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const { service_id } = useSelector((state: RootState) => state.contract)

    let [searchParams, setSearchParams] = useSearchParams();

    const { param_contract_id } = useParams<{
        param_contract_id: string;
    }>()
    const from_param = searchParams.get('from')
    const to_param = searchParams.get('to')
    const tkos_param = searchParams.get('tkos')


    const tkosParamArr = tkos_param ? tkos_param.split(',').map(i => parseInt(i)) : []

    const form: FilterFormType = {
        contract_id: param_contract_id || '',
        array_tko_id: isIterableArray(tkosParamArr) ? tkosParamArr : [],
        periodFrom: from_param || defaulPeriodFrom,
        periodTo: to_param || defaulPeriodTo,
        // month_from: defaultMonthFrom,
        // year_from: defaultYearFrom,
        // month_to: defaultMonthTo,
        // year_to: defaultYearTo,
        // month_from: '05',
        // year_from: 2023,
        // month_to: '07',
        // year_to: 2023,
    }

    const [sendForm, setSendForm] = useState<FilterFormType>(form);

    const dispatch = useDispatch<AppDispatch>()

    const { data, error, isLoading, isFetching } = useViewDeclaredQuery(sendForm)


    const [computedData, setComputedData] = useState([]);

    const [isLoadingSave, setIsLoadingSave] = useState(false);

    const {
        register,
        control,
        handleSubmit,
        watch,
        setValue,
        resetField,
        formState: { touchedFields, dirtyFields },
    } = useForm<any>({
        defaultValues: {
            data: computedData
        },
    })


    const [isLoadingTable, setIsLoadingTable] = useState<boolean>(true);

    useEffect(() => {
        dispatch(setContractId(Number(param_contract_id)))
    }, [param_contract_id]);

    useEffect(() => {
        if (data?.service_id) dispatch(setServiceId(Number(data.service_id)))
        if (data?.data) setComputedData(data.data)
        else setComputedData([])
    }, [data]);



    useEffect(() => {
        if (!isLoading && !isFetching) {
            setIsLoadingTable(false)
        }
    }, [isLoading, isFetching]);

    const setFormForFetch = (values: any) => {
        setIsLoadingTable(true)
        setSendForm(prev => ({
            ...prev, ...{
                ...values,
                contract_id: param_contract_id
            }
        }))
    }
    const handleSetDeclared = (type: 'declared' | 'fact', tko: any) => {
        // console.log(type, tko)
        // ca/consumption-declared/view-last-year

        const form: any = {
            contract_id: param_contract_id || '',
            array_tko_id: isIterableArray(tkosParamArr) ? tkosParamArr : [],
            periodFrom: sendForm.periodFrom,
            periodTo: sendForm.periodTo,
        }


        setIsLoadingTable(true)
        axios.post(`${baseApi}/api/ca/consumption-${type}/view-last-year`, form)
            .then((response: any) => {
                if (response.data.res === 'error') {
                    errorToast({
                        title: t('declared.declared_title'),
                        text: t(`declared.error_on_save_last_year_${type}`)
                    })
                }
                else {
                    successToast({
                        title: t(`declared.last_year_create_success_${type}`)
                    })
                    resetField('data', { keepDirty: false, defaultValue: data?.formData })
                    setComputedData(response.data)

                }
            })
            .catch(error => {
                console.error(error);
                errorToast({
                    title: t('consumption.consumption_title'),
                    text: t(`declared.error_on_save_last_year_${type}`)
                })
            })
            .finally(() => {
                setIsLoadingTable(false)
            })
    }


    useEffect(() => {
        // setValue({
        //     periodFrom: value.periodFrom,
        //     periodTo: value.periodTo,
        //     tko: value.tko,
        // })
        // setValue('data', data?.formData)

    }, [data?.formData]);

    useEffect(() => {
        if (isIterableArray(computedData)) {
            setValue('data', setFormData(computedData))
            // setComputedData([])
        }
    }, [computedData]);

    const onSubmit: SubmitHandler<any> = (values) => {
        // console.log('SUBMIT', data2)
        // console.log('touchedFields', touchedFields)
        // console.log('dirtyFields', dirtyFields)
        // handleUpdatePage(data)


        const changedTkosArray = dataFormatterForSave(values.data, data?.formData)

        if (isIterableArray(changedTkosArray)) {
            saveDeclared(changedTkosArray)
        }
        else {
            errorToast({
                title: t('declared.create_declared_title'),
                text: t('declared.nothing_to_save')
            })
        }
    }

    const saveDeclared = (tko: {
        tko_id: string,
        month: string,
        year: string,
        amount: number
    }[]) => {
        const sendData = {
            date: moment().format('YYYY-MM-DD'),
            comment: '',
            tko: tko
        }
        // ca/consumption
        setIsLoadingSave(true)
        axios.post(`${baseApi}/api/ca/consumption-declared/create-all`, sendData)
            .then((response: any) => {
                if (response.data.res === 'error') {
                    errorToast({
                        title: t('declared.create_declared_title'),
                        text: t('declared.error_on_save')
                    })
                }
                else {
                    successToast({
                        title: t('declared.declared_save_success')
                    })
                    // handleConfirm()
                    // refetch()
                    resetField('data', { keepDirty: false })
                    setIsLoadingTable(true)
                    handleNavigateBack()
                }
            })
            .catch(error => {
                console.error(error);
                errorToast({
                    title: t('declared.create_declared_title'),
                    text: t('declared.error_on_save')
                })
            })
            .finally(() => {
                setIsLoadingSave(false)
            })
    }

    const dataFormatterForSave = (values: any, defaultValues: any) => {
        if (values) {
            const res: any = []
            Object.keys(values).map(key => {
                const [tko_id, month, year] = key.split('-')
                if (values[key] !== defaultValues[key]) {
                    res.push({
                        tko_id,
                        month,
                        year,
                        amount: values[key]
                    });
                }
            })
            return res
        }
        else return []
    }



    const handleNavigateBack = () => {
        if (location.state?.pathname) navigate(location.state.pathname + (location.state?.search ? location.state.search : ''))
        else navigate(`/consumptions/${param_contract_id}`)
    }

    const textService = service_id === 1 ? 'text-orange-500' : service_id === 2 ? 'text-blue-600' : 'text-gray-800'
    const spinnerColor = service_id === 1 ? 'orange' : service_id === 2 ? 'blue' : 'gray'

    return (
        <PageLayout
            contentClassName="mt-[-32px]"
            title={
                <Typography variant='h3' className='text-gray-800 text-center'>
                    {/* mb-[32px] */}
                    {t(`declared.create_declared_title_${data?.service_id || 'no_service'}`)}
                </Typography>
            }>
            <Helmet>
                <title>
                    {t(`declared.create_declared_title_${data?.service_id || 'no_service'}`)}
                </title>
            </Helmet>
            <OverlayUnTouchable isOverlay={(isFetching || isLoading || !!error)} opacity={.25} bg={'bg-gray-50'}>
                <Filters
                    onSubmit={handleSubmit(onSubmit)}
                    value={{
                        periodFrom: sendForm.periodFrom,
                        periodTo: sendForm.periodTo,
                    }}
                    isLoadingFetch={isFetching}
                    isLoadingSave={isLoadingSave}
                    fetchPage={setFormForFetch}
                    handleNavigateBack={handleNavigateBack}
                />

                <div className='tab:hidden mt-[12px] mb-[24px] '>
                    <MobileAmountTitle
                        text={t('consumption.amount_3_month')}
                        amount={data?.amount_3_month}
                        amountTitle={t('kvHour')}
                        isFetching={isLoadingTable}
                        tkos_length={tkosParamArr?.length}
                    />
                </div>
                <div className='hidden tab:flex items-center justify-between mt-[12px] mb-[24px] '>
                    <AmountTitle
                        text={t('consumption.amount_3_month')}
                        amount={data?.amount_3_month}
                        amountTitle={t('kvHour')}
                        isFetching={isLoadingTable}
                    />
                    <Typography variant='lead' className=''>
                        {t('declared.tkos_length')} {tkosParamArr?.length || 0}
                    </Typography>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='tkos-viewport'>
                        {
                            <>
                                {

                                    isIterableArray(computedData) ?
                                        <>
                                            <TkosDeclared
                                                data={computedData}
                                                control={control}
                                                handleSetDeclared={handleSetDeclared}
                                            />
                                        </>
                                        :
                                        isFetching ?
                                            <SkeletonDeclared />
                                            :
                                            error ?
                                                "error"
                                                :
                                                'no tkos for view'
                                }
                            </>
                        }
                    </div>
                </form>
            </OverlayUnTouchable>
        </PageLayout>
    )
}

export default CreateDeclaredPage