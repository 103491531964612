

import { useState } from 'react';

import { visuallyHidden } from '@mui/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { Box, TableSortLabel } from '@mui/material';

import { Button, Menu, MenuHandler, MenuItem, MenuList, Spinner, Typography } from '@material-tailwind/react';

import DownloadIcon from '../../images/icons/DownloadIcon';

import { useTranslation } from 'react-i18next';

import { isIterableArray, numWord, toFormatPrice, toStringPrice, } from '../../utils/utils';
import { OrderType } from '../tableHelpers/tableConfig';
import axios from 'axios';
import { baseApi } from '../../ConfigApi';
import TableToolbar from '../tableHelpers/TableToolbar';
import SelectIcon from '../../images/icons/SelectIcon';
import fileDownload from 'js-file-download';
import { ActRowType, ActsHeadCell } from './ActsPage.types';
import FileIcon from '../../images/icons/FileIcon';
import DownloadBtn from '../tableHelpers/buttons/DownloadBtn';
import { errorToast } from '../../components/toasts/toasts';
import TableEmptyRows from '../tableHelpers/TableEmptyRows';
import ActsCards from './mobile/ActsCards';

type PropsType = {
    isLoadingTable: boolean,
    rows: ActRowType[],
    visibleRows: ActRowType[],
    orderBy: keyof ActRowType,
    order: OrderType,
    service_id: any,
    handleRequestSort: any,
}
const ActTableDumb = (props: PropsType): JSX.Element => {

    const { t } = useTranslation()
    const {
        isLoadingTable,
        rows,
        visibleRows,
        orderBy,
        order,
        service_id,
        handleRequestSort
    } = props

    // ______SERVICE COLORS

    const textSearchService = service_id === 1 ? 'bg-orange-100' : service_id === 2 ? 'bg-blue-100' : 'bg-gray-200'
    const hoverRowService = service_id === 1 ? 'hover:!bg-orange-50' : service_id === 2 ? 'hover:!bg-blue-100' : 'hover:!bg-gray-200'
    const sortIconService = service_id === 1 ? 'svg-sort-orange' : service_id === 2 ? 'svg-sort-blue' : ''

    // array selected id's
    const [selected, setSelected] = useState<number[]>([]);



    // ______CHECKBOXS

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const handleClickSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((item: any) => item.id);
            setSelected(newSelected)
        }
        else setSelected([])
    };

    const handleClickCheckbox = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected)
    };
    // _____


    function TableHeadComponent() {
        const createSortHandler = (property: keyof ActRowType) => (event: React.MouseEvent<unknown>) => {
            handleRequestSort(event, property);
        };
        return (
            <TableHead className='table-head-border border-none font-sans'>
                <TableRow>
                    <TableCell className='font-sans' padding="checkbox" sx={{ paddingBottom: '0', border: 'none' }} >
                        <Checkbox
                            disableRipple={true}
                            className={`svg-checkbox-dark hover:bg-white`}
                            indeterminate={selected.length > 0 && selected.length < rows.length}
                            checked={rows.length > 0 && selected.length === rows.length}
                            onChange={handleClickSelectAll}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={'normal'}
                            sx={{ paddingBottom: '0px !important', paddingTop: '0px !important', border: 'none' }}
                            className={`!font-sans ${headCell.className}`}
                        >
                            {
                                headCell.sorting ?
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                        className={`${sortIconService} text-base !text-gray text-nowrap`}
                                    >
                                        {t(headCell.label)}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden} >
                                                <>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </>
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                    :
                                    <span className='text-base font-normal !text-gray-600 whitespace-nowrap'>
                                        {t(headCell.label)}
                                    </span>
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
    function TableRowsRender({ cells, row }: { cells: ActsHeadCell[], row: ActRowType }) {
        return (
            <>
                {
                    cells.map((cell, index) => {
                        const key = cell.dataIndex
                        return (
                            <TableCell
                                key={cell.dataIndex}
                                align={cell.align}
                                sx={{
                                    border: 'none', lineHeight: '120%',
                                    borderTopRightRadius: index === cells.length - 1 ? 16 : 0,
                                    borderBottomRightRadius: index === cells.length - 1 ? 16 : 0
                                }}
                                padding={'normal'}
                                className='!font-sans !text-gray-700 !text-[16px]'
                            >
                                {
                                    cell.render ?
                                        cell.render(row[key], row)
                                        :
                                        row[key]
                                }
                            </TableCell>
                        )
                    })
                }
            </>
        )
    }

    const headCells: ActsHeadCell[] = [
        {
            dataIndex: 'period', // какое поле в таблице
            id: 'period_sorter', // какое поле в сортировке
            align: 'left',
            sorting: true,
            label: 'acts_page.period',
            className: 'min-w-[80px]',
            render: (item: any, record?: any) => (
                <span className='text-gray-700'>
                    {/* {isSubst(item)} */}
                    {item}
                </span>
            )
        },
        {
            dataIndex: 'number',
            id: 'number',
            sorting: true,
            align: 'left',
            label: 'acts_page.number',
            className: 'min-w-[120px]',
            render: (item: any, record?: any) => (
                <span className='text-gray-700'>
                    {/* {isSubst(item)} */}
                    {item}
                </span>
            )
        },

        {
            dataIndex: 'act_type_table',
            id: 'act_type',
            sorting: true,
            align: 'left',
            label: 'acts_page.act_type',
            className: 'min-w-[170px]',
            render: (item: any, record: any) => (
                <div className={`text-gray-700`}>
                    {item}
                </div>
            )
        },
        {
            dataIndex: 'balance_id_table',
            id: 'balance_id',
            sorting: true,
            align: 'left',
            label: `acts_page.balance_id`,
            className: 'min-w-[170px]',
            render: (item: any, record: any) => (
                <div className={`text-gray-700`}>
                    {item}
                </div>
            )
        },
        {
            dataIndex: 'count',
            id: 'count',
            sorting: true,
            align: 'left',
            label: `acts_page.count`,
            className: 'min-w-[150px]',
            render: (item: any, record: any) => (
                <div className={`text-gray-700`}>
                    {item ? toStringPrice(item) : ' - '}
                </div>
            )
        },
        {
            dataIndex: 'price',
            id: 'price',
            sorting: true,
            align: 'left',
            label: `acts_page.price`,
            className: 'min-w-[150px]',
            render: (item: any, record: any) => (
                <div className={`text-gray-700`}>
                    {toFormatPrice(item)}
                </div>
            )
        },
        {
            dataIndex: 'amount_with_nds',
            id: 'amount_with_nds',
            sorting: true,
            align: 'left',
            label: `acts_page.amount_with_nds`,
            className: 'min-w-[150px]',
            render: (item: any, record: any) => (
                <span className={`text-[16px] font-bold text-gray-700`}>
                    {toFormatPrice(item)}
                </span>
            )
        },
        {
            dataIndex: 'amount_for_payment',
            id: 'amount_for_payment',
            sorting: true,
            align: 'left',
            label: `acts_page.amount_for_payment`,
            className: 'min-w-[150px]',
            render: (item: any, record: any) => (
                <span className={`text-[16px] font-bold text-gray-700`}>
                    {toFormatPrice(item)}
                </span>
            )
        },
        {
            dataIndex: 'id',
            id: 'id',
            align: 'left',
            label: ` `,
            className: 'w-[164px]',
            render: (item: any, record: any) => (
                <div className={`text-gray-600`}>
                    <div className='flex items-center gap-[4px]'>
                        <DownloadBtn
                            icon={<FileIcon />}
                            tooltipText={t('acts_page.download_invoice_act')}
                            record={record}
                            handleDownloadFile={handleDownloadInvoice}
                        />

                        <DownloadBtn
                            icon={<DownloadIcon />}
                            tooltipText={t('acts_page.download_act')}
                            record={record}
                            handleDownloadFile={handleDownloadAct}
                        />

                        <DownloadBtn
                            icon={<span className='fond-bold text-[20px]'>?</span>}
                            tooltipText={t('acts_page.download_detail')}
                            record={record}
                            handleDownloadFile={handleDownloadDetail}
                        />
                    </div>
                </div>
            )
        },
    ];

    const handleDownloadDetail = (record: ActRowType, callback?: () => void) => {
        let form = {
            id: record.id
        }
        let filename = `${record.detailing_name}`

        axios.post(`${baseApi}/api/acts/export-detailing`, form, { responseType: 'blob' })
            .then((response: any) => {
                if (response.data.res === 'error') {
                    errorToast({
                        title: t('acts_page.acts_title'),
                        text: t('error_on_download')
                    })
                }
                else fileDownload(response.data, filename)
            })
            .catch(error => {
                console.error(error);
                errorToast({
                    title: t('acts_page.acts_title'),
                    text: t('error_on_download')
                })
            })
            .finally(() => {
                if (callback) callback()
            })
    }
    const handleDownloadAct = (record: ActRowType, callback?: () => void) => {
        axios.post(`${baseApi}/api/acts/export-file`, record, { responseType: 'blob' })
            .then((response: any) => {
                if (response.data.res === 'error') {
                    errorToast({
                        title: t('acts_page.acts_title'),
                        text: t('error_on_download')
                    })
                }
                else fileDownload(response.data, record.filename)
            })
            .catch(error => {
                console.error(error);
                errorToast({
                    title: t('acts_page.acts_title'),
                    text: t('error_on_download')
                })
            })
            .finally(() => {
                if (callback) callback()
            })
    }
    const handleDownloadInvoice = (record: ActRowType, callback?: () => void) => {
        axios.post(`${baseApi}/api/acts/export-invoice`, record, { responseType: 'blob' })
            .then((response: any) => {
                if (response.data.res === 'error') {
                    errorToast({
                        title: t('acts_page.acts_title'),
                        text: t('error_on_download')
                    })
                }
                else fileDownload(response.data, record.invoiceFilename)
            })
            .catch(error => {
                console.error(error);
                errorToast({
                    title: t('acts_page.acts_title'),
                    text: t('error_on_download')
                })
            })
            .finally(() => {
                if (callback) callback()
            })
    }

    const handleDownloadSelected = (arr: number[]) => {
        axios.post(`${baseApi}/api/acts/export-files-zip`, {
            arr
        }, { responseType: 'blob' })
            .then((response: any) => {
                if (response.data.res === 'error') {
                    errorToast({
                        title: t('acts_page.acts_title'),
                        text: t('error_on_download')
                    })
                }
                else {
                    setSelected([])
                    fileDownload(response.data, `Files.zip`)
                }
            })
            .catch(error => {
                console.error(error);
                errorToast({
                    title: t('acts_page.acts_title'),
                    text: t('error_on_download')
                })
            })
    }



    return (
        <>
            <TableToolbar
                isOpen={selected?.length > 0}
            >
                <Typography
                    color="inherit"
                // component="div" 
                >
                    {t('selected')}
                    <span className='font-bold'> {selected.length}  {t(`acts_page.doc_active_count_${numWord(selected.length)}`)}</span>
                </Typography>
                <Menu placement="top" >
                    <MenuHandler>
                        <Button color='white' className='w-[300px] px-[16px] rounded flex items-center justify-between'>
                            {t('action')}
                            <SelectIcon />
                        </Button>
                    </MenuHandler>
                    <MenuList className='w-[300px]'>
                        <MenuItem
                            className='p-[16px]'
                            onClick={() => {
                                handleDownloadSelected(selected)
                            }}
                        >
                            {t('acts_page.download_selected_documents')}
                        </MenuItem>
                    </MenuList>
                </Menu>
            </TableToolbar>

            <ActsCards
                visibleRows={visibleRows}
                isLoadingTable={isLoadingTable}
                handleDownloadInvoice={handleDownloadInvoice}
                handleDownloadAct={handleDownloadAct}
                handleDownloadDetail={handleDownloadDetail}
            />


            <TableContainer className='hidden tab:block'>
                <Table
                    sx={{
                        minWidth: 750,
                        borderCollapse: 'separate',
                        borderSpacing: '0 16px',
                    }}
                    size={'medium'}
                >
                    <TableHeadComponent />
                    <TableBody>
                        {
                            isIterableArray(visibleRows) ?
                                visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    return (
                                        <TableRow
                                            key={row.id}
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            selected={isItemSelected}
                                            className={`  
                                                table-row-border
                                                service_id_${service_id}
                                                ${hoverRowService}
                                            `}
                                        >
                                            <TableCell
                                                padding="checkbox"
                                                sx={{
                                                    border: 'none', lineHeight: '120%',
                                                    borderTopLeftRadius: 16,
                                                    borderBottomLeftRadius: 16,

                                                }}
                                                onClick={(event: any) => {
                                                    event.stopPropagation()
                                                    handleClickCheckbox(event, row.id)
                                                }}
                                            >
                                                <div className='flex items-center min-h-[64px]'>
                                                    <Checkbox
                                                        disableRipple={true}
                                                        color="default"
                                                        className='svg-checkbox-dark hover:!bg-white'
                                                        checked={isItemSelected}
                                                    />
                                                </div>
                                            </TableCell>
                                            <TableRowsRender cells={headCells} row={row} />
                                        </TableRow>
                                    );
                                })
                                :
                                <TableEmptyRows isLoadingTable={isLoadingTable} />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ActTableDumb
