

import { visuallyHidden } from '@mui/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, TableSortLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isIterableArray, toFormatPrice, } from '../../../utils/utils';
import { OrderType } from '../../tableHelpers/tableConfig';
import { InvoiceHistoryRowType, InvoiceHistoryHeadCell } from '../InvoicePage.types';
import TableEmptyRows from '../../tableHelpers/TableEmptyRows';
import InvoiceHistoryCards from '../mobile/InvoiceHistoryCards';

type PropsType = {
    isLoadingTable: boolean,
    rows: InvoiceHistoryRowType[],
    visibleRows: InvoiceHistoryRowType[],
    orderBy: keyof InvoiceHistoryRowType,
    order: OrderType,
    service_id: any,
    handleRequestSort: any,
}
const InvoiceHistoryTableDumb = (props: PropsType): JSX.Element => {

    const { t } = useTranslation()
    const {
        isLoadingTable,
        rows,
        visibleRows,
        orderBy,
        order,
        service_id,
        handleRequestSort,
    } = props

    // ______SERVICE COLORS

    const textSearchService = service_id === 1 ? 'bg-orange-100' : service_id === 2 ? 'bg-blue-100' : 'bg-gray-200'
    const hoverRowService = service_id === 1 ? 'hover:!bg-orange-50' : service_id === 2 ? 'hover:!bg-blue-100' : 'hover:!bg-gray-200'
    const sortIconService = service_id === 1 ? 'svg-sort-orange' : service_id === 2 ? 'svg-sort-blue' : ''



    function TableHeadComponent() {
        const createSortHandler = (property: keyof InvoiceHistoryRowType) => (event: React.MouseEvent<unknown>) => {
            handleRequestSort(event, property);
        };
        return (
            <TableHead className='table-head-border border-none font-sans'>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={'normal'}
                            sx={{ paddingBottom: '0px !important', paddingTop: '0px !important', border: 'none' }}
                            className={`!font-sans ${headCell.className}`}
                        >
                            {
                                headCell.sorting ?
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                        className={`${sortIconService} text-base !text-gray text-nowrap`}
                                    >
                                        {t(headCell.label)}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden} >
                                                <>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </>
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                    :
                                    <span className='text-base font-normal !text-gray-600 whitespace-nowrap'>
                                        {t(headCell.label)}
                                    </span>
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
    function TableRowsRender({ cells, row }: { cells: InvoiceHistoryHeadCell[], row: InvoiceHistoryRowType }) {
        return (
            <>
                {
                    cells.map((cell, index) => {
                        const key = cell.dataIndex
                        return (
                            <TableCell
                                key={cell.dataIndex}
                                align={cell.align}
                                sx={{
                                    border: 'none', lineHeight: '120%',
                                    borderTopRightRadius: index === cells.length - 1 ? 16 : 0,
                                    borderBottomRightRadius: index === cells.length - 1 ? 16 : 0,
                                    borderTopLeftRadius: index === 0 ? 16 : 0,
                                    borderBottomLeftRadius: index === 0 ? 16 : 0,
                                }}
                                padding={'normal'}
                                className='!font-sans !text-gray-700 !text-[16px]'
                            >
                                {
                                    cell.render ?
                                        cell.render(row[key], row)
                                        :
                                        row[key]
                                }
                            </TableCell>
                        )
                    })
                }
            </>
        )
    }

    const headCells: InvoiceHistoryHeadCell[] = [
        {
            dataIndex: 'date', // какое поле в таблице
            id: 'date_sort', // какое поле в сортировке
            align: 'left',
            sorting: true,
            label: 'invoices_page.history.date',
            className: 'min-w-[80px]',
            render: (item: any, record?: any) => (
                <span className='text-gray-700'>
                    {/* {isSubst(item)} */}
                    {item}
                </span>
            )
        },
        {
            dataIndex: 'amount',
            id: 'amount',
            sorting: true,
            align: 'left',
            label: 'invoices_page.history.amount',
            className: 'min-w-[150px]',
            render: (item: any, record: any) => (
                <span className={`text-[16px] font-bold text-gray-700`}>
                    {toFormatPrice(item)}
                </span>
            )
        },
        {
            dataIndex: 'action',
            id: 'action',
            sorting: true,
            align: 'left',
            label: 'invoices_page.history.action',
            className: 'min-w-[170px]',
            render: (item: any, record: any) => (
                <div className={`text-gray-700`}>
                    {/* {isSubst(t(`invoices_page.invoice_type_${item}`))} */}
                    {/* {t(`invoices_page.history.${item}`)} */}
                    {item}
                </div>
            )
        },
        {
            dataIndex: 'debt',
            id: 'debt',
            sorting: true,
            align: 'left',
            label: 'invoices_page.history.debt',
            className: 'min-w-[200px]',
            render: (item: any, record: any) => (
                <span className={`text-[16px] font-bold text-gray-700`}>
                    {item ? toFormatPrice(item) : <span className='text-gray-500'> — </span>}
                </span>
            )
        },
        {
            dataIndex: 'saldo',
            id: 'saldo',
            sorting: true,
            align: 'left',
            label: 'invoices_page.history.saldo',
            className: 'min-w-[200px]',
            render: (item: any, record: any) => (
                <span className={`text-[16px] font-bold text-gray-700`}>
                    {item ? toFormatPrice(item) : <span className='text-gray-500'> — </span>}
                </span>
            )
        },
        {
            dataIndex: 'overpay_unconfirm',
            id: 'overpay_unconfirm',
            sorting: true,
            align: 'left',
            label: 'invoices_page.history.overpay_unconfirm',
            className: 'min-w-[200px]',
            render: (item: any, record: any) => (
                <span className={`text-[16px] font-bold text-gray-700`}>
                    {item ? toFormatPrice(item) : <span className='text-gray-500'> — </span>}
                </span>
            )
        },
        {
            dataIndex: 'info',
            id: 'info',
            align: 'left',
            label: `invoices_page.history.info`,
            className: 'w-[25%]',
            render: (item: any, record: any) => (
                <div className={`text-gray-600 text-[14px]`}>
                    {/* {isSubst(item)} */}
                    {item ? item : <span className='text-gray-500'> — </span>}
                </div>
            )
        }
    ];



    return (
        <>
            <InvoiceHistoryCards
                visibleRows={visibleRows}  
                isLoadingTable={isLoadingTable} 
            />
            <TableContainer className='hidden tab:block'>
                <Table
                    sx={{
                        minWidth: 750,
                        borderCollapse: 'separate',
                        borderSpacing: '0 16px',
                    }}
                    size={'medium'}
                >
                    <TableHeadComponent />
                    <TableBody>
                        {
                            isIterableArray(visibleRows) ?
                                visibleRows.map((row, index) => {
                                    return (
                                        <TableRow
                                            key={index}
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            className={`  
                                                table-row-border
                                                service_id_${service_id}
                                                ${hoverRowService}
                                            `}
                                        >
                                            <TableRowsRender cells={headCells} row={row} />
                                        </TableRow>
                                    );
                                })
                                :
                                <TableEmptyRows isLoadingTable={isLoadingTable} />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default InvoiceHistoryTableDumb
