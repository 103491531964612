import { Drawer, } from '@material-tailwind/react'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { MOBILE_DIVIDER_OFFSET } from '../../utils/variables'

const DrawerCard = ({
    isOpen,
    closeDrawer,
    children,
    sizeDrawer
}: {
    children: ReactNode,
    isOpen: boolean,
    closeDrawer: any,
    sizeDrawer?: number
}) => {

    const { t } = useTranslation()

    const size = sizeDrawer ? sizeDrawer : window.innerHeight - MOBILE_DIVIDER_OFFSET

    return (
        <Drawer
            placement={'bottom'}
            open={isOpen}
            onClose={closeDrawer}
            className={`p-4 !pb-[48px] ${!isOpen && 'tab:!left-0'} !bottom-0  lap:!max-w-[500px]  flex flex-col overflow-auto no-scrollbar`}
            size={size}
        >
            {
                children &&
                children
            }
        </Drawer>
    )
}

export default DrawerCard