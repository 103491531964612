import { Typography } from '@material-tailwind/react'
import { Skeleton } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import ArrowTopRight from '../../images/icons/ArrowTopRight'
import { useIndexAllQuery } from '../../store/auth/authService'
import { setContractId, setServiceId } from '../../store/service/contractSlice'
import { AppDispatch } from '../../store/store'
import { numWord, toFormatPrice } from '../../utils/utils'
import { currency } from '../../utils/variables'
import { ItemType } from './MainPage.types'
import { resetCrumbs } from '../../store/service/headerSlice'
import { Helmet } from 'react-helmet'
import PageLayout from '../../layouts/pagesLayouts/PageLayout'



const BalanceItem = ({ title, value, isLoading }: { title: string, value: number | undefined, isLoading: boolean }) => (
    <div className='flex flex-col gap-[4px]'>
        <p className='text-[14px] lap:text-[16px]'>
            {title}
        </p>
        <p className={`text-[16px] lap:text-[18px] text-gray-800 font-bold ${value && value < 0 && 'text-red-500'} `}>
            {
                isLoading ?
                    <Skeleton className='max-w-[100px]' />
                    :
                    <>
                        {toFormatPrice(value)} {currency}
                    </>
            }
        </p>
    </div>
)
const ItemCard = (props: ItemType) => {
    const {
        isLoading,
        service_id,
        title,
        active_total,
        balance_1,
        balance_2,
        balance_3,
        balance_4,
        balance_5,
        balance_6
    } = props

    const { t } = useTranslation()

    const dispatch = useDispatch<AppDispatch>()

    const handleClick = (id: number) => {
        dispatch(setServiceId(id))
    }

    return (
        <Link
            className='group'
            to='/my-companies'
            onClick={() => handleClick(service_id)}
        >
            <div className={`
                animate-appear
                bg-white border-2 border-gray-100
                lap:hover:cursor-pointer easy duration-300 transitions-all
                ${service_id === 1 && 'active:border-orange-500 lap:hover:border-orange-500'} 
                ${service_id === 2 && 'active:border-blue-500 lap:hover:border-blue-500'}
                p-[24px] rounded-[16px]   
                tab:p-[48px] 
                lap:p-[48px] lap:rounded-[32px]  
            `}
            >
                <div className='flex flex-col  '>
                    <div className='flex  justify-between'>
                        <div className='flex flex-col gap-[16px] mb-[64px] des:mb-[96px]'>
                            <Typography variant='h3' className={`
                                easy duration-200 transitions-color
                                ${service_id === 1 && 'group-active:text-orange-500 lap:group-hover:text-orange-500'}  
                                ${service_id === 2 && 'group-active:text-blue-500 lap:group-hover:text-blue-500'}  
                            `}>
                                {title}
                            </Typography>
                            {
                                isLoading &&
                                <p className='max-w-[150px]'>
                                    <Skeleton />
                                </p>
                            }
                            {
                                typeof active_total === 'number' &&
                                <p className='text-[14px] mob:text-[16px] lap:text-[20px] '>
                                    {t('mainpage.active_title')}: {active_total} {t(`mainpage.active_count_${numWord(active_total)}`)}
                                </p>
                            }
                        </div>
                        <div className={`
                            ${service_id === 1 && 'group-hover:text-orange-500'}  
                            ${service_id === 2 && 'group-hover:text-blue-500'}  
                            easy duration-300 transitions-all
                            group-active:translate-y-[-5px]
                            group-active:translate-x-[5px]
                            lap:group-hover:translate-y-[-5px]
                            lap:group-hover:translate-x-[5px]
                            group-active:translate-y-[-5px]
                            group-active:translate-x-[5px]
                            pl-5
                        `}
                        >
                            <ArrowTopRight className={` 
                                ${service_id === 1 && 'lap:group-hover:fill-orange-500'}  
                                ${service_id === 2 && 'lap:group-hover:fill-blue-500'} 
                                easy duration-300 transitions-all
                                w-[16px] h-[16px] lap:w-[24px] lap:h-[24px]
                                `}
                            />
                        </div>
                    </div>
                    <div className='flex flex-wrap gap-[16px] mob:gap-[32px] tab:gap-[64px]'>
                        {
                            service_id === 1 &&
                            <>
                                <BalanceItem
                                    isLoading={isLoading}
                                    title={t('mainpage.balance_1')}
                                    value={balance_1}
                                />
                                <BalanceItem
                                    isLoading={isLoading}
                                    title={t('mainpage.balance_2')}
                                    value={balance_2}
                                />
                            </>
                        }
                        {
                            service_id === 2 &&
                            <>
                                <BalanceItem
                                    isLoading={isLoading}
                                    title={t('mainpage.balance_3')}
                                    value={balance_3}
                                />
                                <BalanceItem
                                    isLoading={isLoading}
                                    title={t('mainpage.balance_4')}
                                    value={balance_4}
                                />
                                <BalanceItem
                                    isLoading={isLoading}
                                    title={t('mainpage.balance_5')}
                                    value={balance_5}
                                />
                                <BalanceItem
                                    isLoading={isLoading}
                                    title={t('mainpage.balance_6')}
                                    value={balance_6}
                                />
                            </>
                        }
                    </div>
                </div>
            </div>
        </Link>
    )
}
const MainPage = () => {

    const { t } = useTranslation()

    const { data, error, isLoading } = useIndexAllQuery('')

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(setContractId(0))
        dispatch(resetCrumbs())
    }, []);

    return (
        // px-[48px]
        <PageLayout>
            <div className='pt-header'>
                <Helmet>
                    <title>{t('crumb_main')}</title>
                </Helmet>
                <div className='grid lap:grid-cols-2 gap-[24px]'>
                    <ItemCard
                        service_id={1}
                        {...data?.svet}
                        isLoading={isLoading}
                        title={t('mainpage.electro_title')}
                    />
                    <ItemCard
                        service_id={2}
                        {...data?.gaz}
                        isLoading={isLoading}
                        title={t('mainpage.gas_title')}
                    />
                </div>
            </div>
        </PageLayout>
    )
}

export default MainPage