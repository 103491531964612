import { Button } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import AmountTitle from '../AmountTitle'
import MobileAmountTitle from '../MobileAmountTitle'

const MatrixAmountButtons = ({ DownloadExcelButton, isEditAccess, handleSave, amount, isLoadingSave, handleNavigateBack }: any) => {

    const { t } = useTranslation()

    return (

        <div className='my-[24px] tab:my-[32px] flex flex-wrap items-center justify-between gap-[16px]'>
            {
                amount !== false &&
                <>
                    <div className='tab:hidden grow'>
                        <MobileAmountTitle
                            text={t('consumption.amount_3_month')}
                            amount={amount}
                            amountTitle={t('kvHour')}
                            isFetching={false}
                        />
                    </div>
                    <div className='hidden tab:block'>
                        <AmountTitle
                            text={t('consumption.amount_total_month')}
                            amount={amount}
                            amountTitle={t('kvHour')}
                            isFetching={false}
                        />
                    </div>
                </>
            }
            <div className="grow tab:grow-0 flex items-center gap-[8px]">
                <Button
                    // disabled={!computedLink}
                    color='white'
                    className={`relative hidden tab:flex`}
                    onClick={handleNavigateBack}
                >
                    {t('to_back')}
                </Button>
                {
                    DownloadExcelButton &&
                    <DownloadExcelButton />
                }
                {
                    isEditAccess &&
                    <Button color='black' onClick={handleSave} loading={isLoadingSave} className='w-full'>
                        {t('consumption.save_button')}
                    </Button>
                }
            </div>
        </div>
    )
}

export default MatrixAmountButtons
