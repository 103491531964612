import { Button, Input, Spinner, Typography } from '@material-tailwind/react'
import React, { useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../store/auth/authActions';
import { AppDispatch, RootState } from '../../store/store';


const LoginForm = () => {

    const navigate = useNavigate()
    const { t } = useTranslation()
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const { loading, error } = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch<AppDispatch>()

    const handleEnter = () => {

        dispatch(loginUser({
            email: email,
            password: password,
            lang: 'ru'
        }))
            .then((res) => {
                if (res?.payload?.res === 'Success') navigate('/', { replace: true });
            });
    }


    return (
        <div className='w-full'>

            <Typography variant='h3' className='mb-[64px] font-normal text-center  pt-[20px]' >
                <span dangerouslySetInnerHTML={{ __html: t('auth.form_title') }} />
            </Typography>
            <div className='w-full'>
                <div className='mt-[40px] text-[14px] tab:text-[16px]'>
                    <Input
                        variant='static'
                        type={'email'}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                        label={t('auth.input_email_label')}
                        placeholder={t('auth.input_email_placeholder')}
                    />
                </div>
                <div className='mt-[64px] text-[14px] tab:text-[16px]'>
                    <Input
                        variant='static'
                        type={'password'}
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                        label={t('auth.input_password_label')}
                        placeholder={t('auth.input_password_placeholder')}
                    />
                </div>

                <Link to='/forgot-password' className='block mt-[24px] text-gray-600 text-[14px] tab:text-[18px]'>
                    {t('auth.forgot_text')}
                </Link>
                <Typography variant='lead' className='flex items-center justify-center font-medium text-[14px] text-center text-red-500 min-h-[32px] mt-[16px]'>
                    {error && t('auth.error_on_login')}
                </Typography>
                <div className='mt-[16px] flex flex-col gap-[8px]'>
                    <Button
                        disabled={loading}
                        variant='outlined'
                        className='font-normal rounded-full border-2 text-[14px] tab:text-[18px] w-full tab:py-[20px] '
                        onClick={() => {
                            handleEnter()
                        }}>
                        {
                            loading
                                ?
                                <Spinner color='blue' className='text-blue-100 mx-auto' />
                                :
                                t('auth.enter_btn')
                        }
                    </Button>
                    <NavLink to='/new-connection'>
                        <Button variant='outlined' className='font-normal rounded-full bg-blue-100 border-2 border-blue-200 text-[14px] tab:text-[18px] w-full tab:py-[20px] '>
                            {t('auth.become_client')}
                        </Button>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default LoginForm
