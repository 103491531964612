import { useLayoutEffect, useState } from 'react';
// import debounce from 'lodash/debounce';
function debounce(func: any, timeout: number) {
    let timer: any;
    return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func(args) }, timeout);
    };
}
const useIsMobile = (screenWidth: number): boolean => {
    const [isMobile, setIsMobile] = useState(true);

    useLayoutEffect(() => {
        const updateSize = (): void => {
            setIsMobile(window.innerWidth < screenWidth);
        };
        // window.addEventListener('resize', updateSize);
        window.addEventListener('resize', debounce(updateSize, 250));
        updateSize();
        return (): void => window.removeEventListener('resize', updateSize);
    }, [screenWidth]);

    return isMobile;
};

export default useIsMobile;
