import { Button, Dialog, DialogBody, Typography } from '@material-tailwind/react';
import { ReactPortal, useState } from 'react'
import { createPortal } from 'react-dom';
import CloseDrawerIcon from '../../images/icons/CloseDrawerIcon';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { baseApi } from '../../ConfigApi';
import { errorToast, successToast } from '../../components/toasts/toasts';

const DeleteAvatarModal = ({ open, handleConfirm, handleClose }: {
    open: boolean,
    handleConfirm: any,
    handleClose: () => void
}): ReactPortal | null => {

    const { t } = useTranslation()


    const [isLoadingDelete, setIsLoadingDelete] = useState(false)

    const deleteAvatar = () => {
        setIsLoadingDelete(true)
        axios.get(`${baseApi}/api/cabinet/profile-clearavatar`)
            .then((response: any) => {
                if (response.data.res === 'Error') {
                    errorToast({
                        title: t('profile.profile_avatar'),
                        text: t('profile.error_on_delete_avatar')
                    })
                }
                else {
                    successToast({
                        title: t('profile.profile_avatar'),
                        text: t('profile.success_delete_avatar')
                    })
                    handleClose()
                    handleConfirm()
                }
            })
            .catch(error => {
                console.error(error);
                errorToast({
                    title: t('profile.profile_avatar'),
                    text: t('profile.error_on_delete_avatar')
                })
            })
            .finally(() => {
                setIsLoadingDelete(false)
            })
    }

    // const Loader = () => {
    //     if (!requestRes) return (
    //         <div className="animation-appear flex flex-col items-center justify-center">
    //             <Spinner />
    //             <Typography variant='lead' className='text-[14px] text-gray-700 font-normal mt-[12px]'>{t('loading')}</Typography>
    //         </div>
    //     )
    //     if (requestRes === 'success') return (
    //         <div className="animation-appear flex flex-col items-center justify-center">
    //             <CheckFilledIcon className='fill-green-500  w-[20px] h-[20px] ' />
    //             <Typography variant='lead' className='text-[14px] text-gray-700 font-normal mt-[12px]'>{t('success')}</Typography>
    //         </div>
    //     )
    //     if (requestRes === 'error') return (
    //         <div className="animation-appear flex flex-col items-center justify-center">
    //             <div className="flex items-center justify-center rounded-full min-w-[20px] h-[20px] bg-red-500">
    //                 <CloseDrawerIcon className='stroke-white w-[10px] h-[10px] ' />
    //             </div>
    //             <Typography variant='lead' className='text-[14px] text-gray-700 font-normal mt-[12px]'>{t('error')}</Typography>
    //         </div>
    //     )
    // }
    return (


        createPortal(
            <>
                <Dialog
                    handler={handleClose} open={open} className='!transition-all !animate-none !min-w-0 !max-w-[640px] bg-white p-[24px] tab:p-[32px] rounded-[16px] '>
                    <DialogBody className="p-0 !duration-100">
                        {

                            <div className=" flex flex-col gap-[32px]">
                                <div className="flex items-center justify-between ">
                                    <Typography variant="lead" className="text-[24px] text-gray-800 font-medium ">
                                        {t('profile.confirm_delete_avatar')}
                                    </Typography>
                                    <div onClick={handleClose} className="rounded-full w-[32px] h-[32px] transition-all flex items-center justify-center active:bg-gray-200 hover:bg-gray-100 cursor-pointer">
                                        <CloseDrawerIcon />
                                    </div>
                                </div>
                                {
                                    // isLoadingDelete ?
                                    //     <div className="min-h-[200px] flex items-center justify-center ">
                                    //         {Loader()}
                                    //     </div>
                                    //     :
                                    <div className="flex item-end">
                                        <div className='grow flex item-center justify-between mt-auto'>
                                            <Button color="white" onClick={handleClose}>
                                                {t('cancel')}
                                            </Button>
                                            <Button
                                                color="red"
                                                onClick={deleteAvatar}
                                                loading={isLoadingDelete}
                                                disabled={isLoadingDelete}
                                            >
                                                {t('confirm')}
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </DialogBody>
                </Dialog>
            </>,
            document.getElementById('root')!
        )
    )
}

export default DeleteAvatarModal