import React from 'react'
import { useTranslation } from 'react-i18next';
import SwitchGasIcon from '../../images/icons/SwitchGasIcon';
import SwitchElectroIcon from '../../images/icons/SwitchElectroIcon';

import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../../store/store';
import { setServiceId } from '../../store/service/contractSlice';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

const SwitchServiceType = () => {

    const { t } = useTranslation()

    // let service_id = 2

    const { pathname } = useLocation()

    const modeSwitch = pathname !== '/my-companies' ? true : false

    const navigate = useNavigate()

    const service_id = useSelector((state: RootState) => state.contract.service_id)
    const dispatch = useDispatch<AppDispatch>()

    // 1 - э/э - false
    // 2 - газ - true
    const isChecked = service_id === 2 ? true : false
    // const [isChecked, setIsChecked] = useState(service_id === 2 ? true : false);
    // const [isLoading, setIsLoading] = useState(false);
    // const [isDisabled, setIsDisabled] = useState(false);

    const handleChangeService = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (pathname !== '/my-companies') {
            navigate('/')
        }
        else {
            dispatch(setServiceId(e.target.checked ? 2 : 1))
        }
    }
    if (pathname === '/') return <div className='min-w-[80px] lap:min-w-[230px]'></div>
    return (
        <div className='flex items-center gap-[8px] min-w-[80px] lap:min-w-[230px]'>
            <div className="relative h-[32px] w-[60px]">
                <input
                    disabled={!service_id}
                    checked={isChecked}
                    onChange={handleChangeService}
                    id="switch-component-custom"
                    type="checkbox"
                    className="peer appearance-none h-[32px] w-[60px] rounded-full
                    bg-switch-ee checked:bg-blue-300 
                    cursor-pointer easy transition-colors duration-200
                    disabled:bg-gray-200
                    "
                />
                <label
                    htmlFor="switch-component-custom"
                    className=" 
                        flex items-center justify-center
                        absolute z-10 top-[2px]  right-[2px] w-[28px] h-[28px] 
                        peer-checked:translate-x-[-28px] 
                        bg-orange-500 rounded-full  peer-checked:bg-blue-600 cursor-pointer
                        easy transition-all duration-200

                        peer-disabled:bg-gray-300
                        "
                >
                    {
                        isChecked ?
                            <SwitchGasIcon />
                            :
                            <SwitchElectroIcon className='w-[12px] h-[16px]' />
                    }
                </label>
                <div className="
                        flex items-center justify-around
                        absolute top-[2px] left-[2px] w-[56px] h-[28px] 
                        pointer-events-none
                    ">
                    <SwitchGasIcon className='fill-white' />
                    <SwitchElectroIcon className='fill-white w-[12px] h-[16px]' />
                </div>

            </div>
            <label htmlFor="switch-component-custom" className={`hidden lap:block mb-0 select-none cursor-pointer text-gray-700 ${!service_id ? '!text-gray-300' : ''}`}>
                {
                    (service_id && service_id >= 1) ?
                        isChecked ?
                            t('switch_gas_title')
                            :
                            t('switch_electro_title')
                        :
                        ''
                }

            </label>
            {
                !service_id &&
                <NavLink to='/' className='hidden tab:block'>
                    {t('switch_start_link_title')}
                </NavLink>
            }
            {modeSwitch}
        </div>
    )
}

export default SwitchServiceType
