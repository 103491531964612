import { Button, Typography } from '@material-tailwind/react'
import { Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import EyeIcon from '../../images/icons/EyeIcon'
import PencilIcon from '../../images/icons/PencilIcon'
import { RootState } from '../../store/store'
import { isIterableArray, toStringPrice } from '../../utils/utils'
import AmountDeltaPopover from './AmountDeltaPopover'
import { TypeTkoPeriodItem } from './_types'

const EMPTY_DATA = '---'

const PeriodsTkos = ({ data, tkoDetailing, isFetching, handleEditDeclared }: {
    data: TypeTkoPeriodItem[],
    isFetching: boolean,
    tkoDetailing: number,
    handleEditDeclared: ({ item, edit }: { item: TypeTkoPeriodItem, edit: boolean }) => void
}) => {

    const { param_contract_id } = useParams()

    const { t } = useTranslation()

    const { pathname, search } = useLocation()

    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const textService = service_id === 1 ? 'text-orange-500 hover:text-orange-600' : service_id === 2 ? 'text-blue-600 hover:text-blue-700' : 'text-gray-100'
    const amountDeltaService = service_id === 1 ? 'text-badge-orange border-badge-orange ' : service_id === 2 ? 'text-badge-blue border-badge-blue ' : ''

    const getHrefViewAll = (tkos: any, type: 'fact' | 'detailing'): any => {
        const tkosStr = isIterableArray(tkos) ? tkos.join(',') : false
        if (tkosStr) {
            const href = `/consumptions/${param_contract_id}/view-all-${type}/` + '?&tkos=' + tkosStr
            return href
        }
        return null
    }

    // ключи нужно будет кое-какие добавить. но это не критично, нужно только для верстки 

    return (
        <div className='min-w-[600px] '>
            {
                isIterableArray(data) &&
                <div className='flex flex-col gap-[16px] lap:gap-[32px]  '>
                    <div className='grid grid-cols-[20%_26%_27%_27%] tab:grid-cols-4 gap-[15px] text-gray-600'>
                        <div className='px-[8px] lap:px-[16px]'>{t('consumption.period')}</div>
                        <div className='px-[8px] lap:px-[16px]'>{t('consumption.declared')}</div>
                        <div className='px-[8px] lap:px-[16px]'>{t('consumption.detailing')}</div>
                        <div className='px-[8px] lap:px-[16px]'>{t('consumption.fact')}</div>
                    </div>
                    {

                        data.map((item: any, key: number) => (
                            <div key={key} className='grid grid-cols-[20%_26%_27%_27%] tab:grid-cols-4 text-[14px] tab:text-[16px] lap:text-[18px] gap-[15px]'>
                                {
                                    isFetching ?
                                        <>
                                            <Skeleton className='max-w-[120px] lap:mx-[16px]' />
                                            <Skeleton className='max-w-[120px] lap:mx-[16px]' />
                                            <Skeleton className='max-w-[120px] lap:mx-[16px]' />
                                            <Skeleton className='max-w-[120px] lap:mx-[16px]' />
                                        </>
                                        :
                                        <>
                                            <div className='leading-[2] px-[8px] lap:px-[16px] text-[14px] tab:text-[16px]'>{item.period}</div>

                                            {/* declared */}
                                            {/* justify-between grid grid-cols-2 */}
                                            {
                                                item.declared &&
                                                <div className='flex items-center gap-[16px] font-bold'>
                                                    {
                                                        typeof item.declared.amount === 'number' ?
                                                            // grow grid grid-cols-[40%_60%]
                                                            <div className={`
                                                            ${((item.show && !item.isClose) || item.declared.amount_delta) && 'hover:bg-gray-50'} 
                                                            flex items-center justify-between w-[100%] py-[4px] px-[8px] lap:px-[16px] rounded transition-all
                                                        `}>
                                                                <span className='leading-[2] text-[14px] tab:text-[16px]'>
                                                                    {
                                                                        item.declared.amount_delta ?
                                                                            toStringPrice(item.declared.amount_delta + item.declared.amount)
                                                                            :
                                                                            toStringPrice(item.declared.amount)
                                                                    }
                                                                </span>

                                                                <div className='flex items-center gap-[8px]'>
                                                                    {
                                                                        item.show && !item.isClose &&
                                                                        <Button
                                                                            color="white"
                                                                            size='sm'
                                                                            className={`w-[32px] h-[32px] flex items-center justify-center`}
                                                                            onClick={() => handleEditDeclared({
                                                                                item: item,
                                                                                edit: true
                                                                            })}
                                                                        >
                                                                            <PencilIcon />
                                                                        </Button>
                                                                    }
                                                                    {
                                                                        item.declared.amount_delta ?
                                                                            <AmountDeltaPopover
                                                                                amount={item.declared.amount}
                                                                                amount_delta={item.declared.amount_delta}
                                                                            />
                                                                            :
                                                                            <div className='w-[20px]' />
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            (item.show && !item.isClose) ?
                                                                <Typography
                                                                    variant='lead'
                                                                    className={`px-[8px] lap:px-[16px]  font-medium transition-all cursor-pointer ${textService}`}
                                                                    onClick={() => handleEditDeclared({
                                                                        item: item,
                                                                        edit: false,
                                                                    })}
                                                                >
                                                                    {t('consumption.create_declared')}
                                                                </Typography>
                                                                :
                                                                EMPTY_DATA
                                                    }
                                                </div>
                                            }

                                            {/* detailing */}
                                            {
                                                item.detailing &&
                                                <div className='flex items-center gap-[16px] font-bold'>
                                                    {
                                                        typeof item.detailing.amount === 'number' ?
                                                            <div className={`
                                                        ${((item.show && !item.isClose) || isIterableArray(item.detailing.arr_id)) && 'hover:bg-gray-50'} 
                                                        flex items-center justify-between w-[100%] py-[4px] px-[8px] lap:px-[16px] rounded transition-all
                                                    `}>
                                                                <span className='leading-[2] text-[14px] tab:text-[16px]'>
                                                                    {toStringPrice(item.detailing.amount)}
                                                                </span>

                                                                <div className='flex items-center gap-[8px]'>
                                                                    {
                                                                        item.show && !item.isClose &&
                                                                        <>

                                                                            <NavLink
                                                                                state={{
                                                                                    pathname, search
                                                                                }}
                                                                                to={`/consumptions/${param_contract_id}/detailing/view/${item.detailing.id}`}
                                                                            >
                                                                                <Button
                                                                                    color="white"
                                                                                    size='sm'
                                                                                    className={`w-[32px] h-[32px] flex items-center justify-center`}
                                                                                >
                                                                                    <EyeIcon />
                                                                                </Button>
                                                                            </NavLink>
                                                                            <NavLink
                                                                                state={{
                                                                                    pathname, search
                                                                                }}
                                                                                to={`/consumptions/${param_contract_id}/detailing/edit/${item.tko_id}/${item.detailing.id}`}>
                                                                                <Button
                                                                                    color="white"
                                                                                    size='sm'
                                                                                    className={`w-[32px] h-[32px] flex items-center justify-center`}
                                                                                >
                                                                                    <PencilIcon />
                                                                                </Button>
                                                                            </NavLink>
                                                                        </>
                                                                    }
                                                                    {
                                                                        isIterableArray(item.detailing.arr_id) &&
                                                                        <NavLink
                                                                            state={{
                                                                                pathname, search
                                                                            }}
                                                                            to={getHrefViewAll(item.detailing.arr_id, 'detailing')}>
                                                                            <Button
                                                                                color="white"
                                                                                size='sm'
                                                                                className={`w-[32px] h-[32px] flex items-center justify-center`}
                                                                            >
                                                                                <EyeIcon />
                                                                            </Button>
                                                                        </NavLink>
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            (
                                                                // показываем создать детализацию 
                                                                item.show &&
                                                                !item.isClose &&
                                                                tkoDetailing !== 3 // если detailing не 3
                                                            ) ?
                                                                <NavLink
                                                                    state={{
                                                                        pathname, search
                                                                    }}
                                                                    to={`/consumptions/${param_contract_id}/detailing/create/${item.tko_id}/${item.year}/${item.month}`}
                                                                    className={`px-[8px] lap:px-[16px] font-medium transition-all ${textService}`}
                                                                >
                                                                    {t('consumption.create_detailing')}
                                                                </NavLink>
                                                                :
                                                                EMPTY_DATA
                                                    }
                                                </div>
                                            }

                                            {/* fact */}
                                            {
                                                item.fact &&
                                                <div className='flex items-center gap-[16px] font-bold'>
                                                    {
                                                        typeof item.fact.amount === 'number' ?
                                                            <div className={`
                                                            ${((item.show && !item.isClose) || isIterableArray(item.fact.arr_fact_id)) && 'hover:bg-gray-50'} 
                                                            flex items-center justify-between w-[100%] py-[4px] px-[8px] lap:px-[16px] rounded transition-all
                                                        `}>
                                                                <span className='leading-[2]'>{toStringPrice(item.fact.amount)}</span>

                                                                <div className='flex items-center gap-[8px]'>
                                                                    {
                                                                        item.show && !item.isClose &&
                                                                        <>
                                                                            <NavLink
                                                                                state={{
                                                                                    pathname, search
                                                                                }}
                                                                                to={`/consumptions/${param_contract_id}/view-factual/${item.fact.id}`}>
                                                                                <Button
                                                                                    color="white"
                                                                                    size='sm'
                                                                                    className={`w-[32px] h-[32px] flex items-center justify-center`}
                                                                                >
                                                                                    <EyeIcon />
                                                                                </Button>
                                                                            </NavLink>
                                                                        </>
                                                                    }
                                                                    {
                                                                        isIterableArray(item.fact.arr_fact_id) &&
                                                                        <NavLink
                                                                            state={{
                                                                                pathname, search
                                                                            }}
                                                                            to={getHrefViewAll(item.fact.arr_fact_id, 'fact')}>
                                                                            <Button
                                                                                color="white"
                                                                                size='sm'
                                                                                className={`w-[32px] h-[32px] flex items-center justify-center`}
                                                                            >
                                                                                <EyeIcon />
                                                                            </Button>
                                                                        </NavLink>
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            // (item.show && !item.isClose) ?
                                                            //     <NavLink to={'#'} className={`font-medium transition-all ${textService}`}>
                                                            //         {t('consumption.create_fact')}
                                                            //     </NavLink>
                                                            //     :
                                                            EMPTY_DATA
                                                    }
                                                </div>
                                            }
                                        </>}
                            </div>
                        ))
                    }
                </div>
            }
        </div >
    )
}

export default PeriodsTkos