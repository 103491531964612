import { useState } from 'react'
import DrawerUserMenu from './DrawerUserMenu'
import ModalNewConnnection from '../newConnection/ModalNewConnnection'
import UserAvatar from './UserAvatar'

const UserMenu = () => {

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false)

    const handleCloseDrawer = () => {
        setIsOpenDrawer(false)
    }
    const handleOpenDrawer = () => {
        setIsOpenDrawer(true)
    }
    const handleCloseModal = () => {
        setIsOpenModal(false)
    }
    const handleOpenModal = () => {
        setIsOpenDrawer(false)
        setIsOpenModal(true)
    }

    return (
        <div>
            <ModalNewConnnection
                open={isOpenModal}
                handleClose={handleCloseModal}
            />
            <DrawerUserMenu
                open={isOpenDrawer}
                handleClose={handleCloseDrawer}
                handleNewConnection={handleOpenModal}
            />
            <UserAvatar handleOpenDrawer={handleOpenDrawer} />
        </div>
    )
}

export default UserMenu