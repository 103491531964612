import { Spinner } from '@material-tailwind/react'
import { useState } from 'react'
import useModal from '../../utils/useModal'
import ItemLayout from './ItemLayout'
import ItemTkoInfo from './ItemTkoInfo'
import ModalEditDeclared from './ModalEditDeclared'
import ModalEditName from './ModalEditName'
import SummaryConsumpiton from './SummaryConsumpiton'
import { TypeConsumptionPage, TypeTkoInfo } from './_types'


const TkosConsumption = ({ data, isFetching, refetch, refetchTkoList }: {
    data: TypeConsumptionPage,
    isFetching: boolean,
    refetch: any,
    refetchTkoList: any,
}) => {

    const modalDeclared = useModal()
    const modalName = useModal()

    const [modalValue, setModalValue] = useState<any>(null);

    const handleEditDeclared = ({ item, edit }: { item: any, edit: boolean }) => {
        modalDeclared.toggle()
        setModalValue({ ...item, edit: edit })
    };
    const handleCloseDeclared = () => {
        modalDeclared.toggle()
        setModalValue(null)
    };
    const handleConfirmDeclared = () => {
        modalDeclared.toggle()
        refetch()
    };
    const handleChangeName = (tko: TypeTkoInfo) => {
        modalName.toggle()
        setModalValue(tko)
    };
    const handleCloseName = () => {
        modalName.toggle()
        setModalValue(null)
    };
    const handleConfirmEditName = () => {
        modalName.toggle()
        refetch()
        refetchTkoList()
    };

    return (
        <div>
            <ModalEditName
                modalValue={modalValue}
                open={modalName.isShowing}
                handleClose={handleCloseName}
                handleConfirm={handleConfirmEditName}
            />
            <ModalEditDeclared
                modalValue={modalValue}
                open={modalDeclared.isShowing}
                handleClose={handleCloseDeclared}
                handleConfirm={handleConfirmDeclared}
            />

            <SummaryConsumpiton data={data?.summary} isFetching={isFetching} />
            {
                isFetching ?
                    <div className="flex items-center justify-center my-[48px]"><Spinner /></div>
                    :
                    data?.tkos?.map((tkoData: any, key: number) => (
                        <ItemLayout key={key} tkoData={tkoData} handleEditDeclared={handleEditDeclared}>
                            <ItemTkoInfo
                                handleChangeName={handleChangeName}
                                tko={tkoData.tko}
                            />
                        </ItemLayout>
                    ))
            }
        </div>
    )
}

export default TkosConsumption