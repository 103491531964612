import { Button, Menu, MenuHandler, MenuItem, MenuList, Typography } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import MoreDotsIcon from '../../../images/icons/MoreDotsIcon'
import { TypeTkoInfo } from '../_types'

const ItemTkoInfo = ({ tko, handleSetDeclared }: { tko: TypeTkoInfo, handleSetDeclared: any }) => {

    const { t } = useTranslation()


    const ItemText = ({ title, text }: any) => (
        <div className='flex flex-col lap:flex-row items-baseline gap-[8px] '>
            <Typography variant='lead' className='text-[14px] tab:text-[16px] text-gray-700 font-[400] mb-[4px]'>
                {title}
            </Typography>
            <Typography variant='lead' className='text-[14px] tab:text-[16px] text-gray-800 font-medium'>
                {text}
            </Typography>
        </div >
    )

    return (
        <>
            <div className="flex flex-col">
                <div className='flex items-center gap-[24px] mb-[24px]'>
                    <Menu placement="bottom-end">
                        <MenuHandler>
                            <Button
                                color="white"
                                size='sm'
                                className='w-[40px] h-[40px] flex items-center justify-center'
                                onClick={(event: any) => {
                                    event.stopPropagation()
                                }}
                            >
                                <MoreDotsIcon />
                            </Button>
                        </MenuHandler>
                        <MenuList>
                            <MenuItem className='block px-[12px] py-[9px]' onClick={() => handleSetDeclared('declared', tko)}>
                                {t('declared.declared_last_year')}
                            </MenuItem>
                            <MenuItem className='block px-[12px] py-[9px] ' onClick={() => handleSetDeclared('fact', tko)}>
                                {t('declared.fact_last_year')}
                            </MenuItem>
                        </MenuList>
                    </Menu>
                    <div className='flex flex-col tab:gap-[4px]'>
                        <Typography variant='lead' className='text-[14px] tab:text-[16px] text-gray-700 font-[400]'>
                            {t('consumption.tko_text_title')}
                        </Typography>
                        <Typography variant='lead' className='text-gray-800 font-medium text-[20px] tab:text-[24px]'>
                            {tko.name}
                        </Typography>
                    </div>
                </div>
                <div className='flex lap:flex-col flex-wrap gap-[32px] lap:gap-[8px]'>
                    <ItemText
                        title={t('consumption.eic')}
                        text={tko.eic}
                    />
                    <ItemText
                        title={t('consumption.class')}
                        text={tko.class}
                    />
                    <ItemText
                        title={t('consumption.group')}
                        text={tko.group}
                    />
                </div>
            </div>
            <hr className='lap:hidden my-[24px] border-gray-100 w-full' />
            <div className="flex flex-col lap:ml-[96px]">
                <Typography variant='lead' className='text-gray-700 font-[400]'>
                    {t('declared.middle_3_month')}
                </Typography>
                <Typography variant='lead' className='text-gray-800 font-medium text-[20px] tab:text-[24px]'>
                    {typeof tko?.amount_3_month === 'number' ? (tko.amount_3_month + " " + t('kvHour')) : ' - '}
                </Typography>
            </div>
            <hr className='lap:hidden my-[24px] border-gray-100 w-full' />


        </>
    )
}

export default ItemTkoInfo
