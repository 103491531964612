import { useEffect, useState } from 'react'
import { Popover, PopoverContent, PopoverHandler, Typography } from '@material-tailwind/react'

import { useController } from 'react-hook-form'
import MonthYearSelect from './MonthYearSelect'
import { DateSelectType } from '../../contract/ContractPage.types'
import { placement } from '@material-tailwind/react/types/components/menu'
import CalendarIcon from '../../../images/icons/CalendarIcon'
import BtnFilter from './BtnFilter'

type propTypes = {
    control: any,
    fieldName: string,
    label: string,
    datesData: DateSelectType,
    handleCloseFilter: any,
    placement: placement,
    isLoadingFetch: boolean,
    isDisabled: (date: string) => boolean,
    // disabledDate?: string
}
const FilterDateItem = (props: propTypes) => {

    const { placement, control, fieldName, label, datesData, handleCloseFilter, isLoadingFetch, isDisabled } = props

    const [open, setOpen] = useState(false);


    // const defaultYear = 0
    // const defaultMonth = ''
    // const defaultYear = Number(moment().format('YYYY'))
    // const defaultMonth = moment().format('MM') 

    const [isLoading, setIsLoading] = useState(false);



    // const defaultValue = defaultMonth + '-' + defaultYear
    const defaultValue = ''
    const {
        field: { value, onChange }
    } = useController({
        name: fieldName,
        control,
        defaultValue: defaultValue
    });

    // const [activeYear, setActiveYear] = useState<number>(Number(moment(value, 'MM-YYYY').format('YYYY') || 0));
    // const [activeMonth, setActiveMonth] = useState<string>(moment(value, 'MM-YYYY').format('MM') || '');

    const handleSelectDate = (date: string) => {
        setIsLoading(true)
        onChange(date)
        handleCloseFilter()
        setOpen(false)
    }
    
    useEffect(() => {
        if (!isLoadingFetch) setIsLoading(false)
    }, [isLoadingFetch]);

    return (
        <div className=''>
            <Popover
                placement={placement}
                open={open}
                handler={(e) => {
                    setOpen(e)
                    // if (!e && activeYear && activeMonth) {
                    //     setIsLoading(true)
                    //     onChange(`${activeMonth}-${activeYear}`)
                    //     handleCloseFilter()
                    // }
                }
                }
            >
                <PopoverHandler >
                    <div className='w-[200px] des:w-[224px]'>
                        <BtnFilter
                            icon={<CalendarIcon />}
                            isLoading={isLoading}
                        >
                            {value ? value : label}
                        </BtnFilter>
                    </div>
                </PopoverHandler >
                <PopoverContent className='p-[24px] z-[11]'>
                    <Typography variant='h4' className='mb-[24px] text-gray-800 !text-[20px]'>
                        {label}
                    </Typography>
                    <MonthYearSelect
                        handleSelectDate={handleSelectDate}
                        isDisabled={isDisabled}
                        // disabledDate={disabledDate}
                        datesData={datesData}
                        value={value}
                        // activeYear={activeYear}
                        // setActiveYear={setActiveYear}
                        // activeMonth={activeMonth}
                        // setActiveMonth={setActiveMonth}
                    />
                </PopoverContent>
            </Popover>
        </div >
    )
}

export default FilterDateItem
