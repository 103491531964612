import { Button, Menu, MenuHandler, MenuList, Spinner } from '@material-tailwind/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckIcon from '../../images/icons/CheckIcon'
import SelectIcon from '../../images/icons/SelectIcon'
import { isIterableArray } from '../../utils/utils'
import { ActiveDateType, DateSelectType } from './ContractPage.types'

const DateSelect = ({ activeDate, setActiveDate, data }: {
    activeDate: ActiveDateType, setActiveDate: (value: ActiveDateType) => void, data: DateSelectType | null
}) => {

    const { t } = useTranslation()

    const [isOpen, setIsOpen] = useState(false);

    const [activeYear, setActiveYear] = useState<number>(activeDate.year);
    const [activeMonth, setActiveMonth] = useState<string>(activeDate.month);

    return (
        <Menu
            open={isOpen}
            handler={setIsOpen}
            offset={16}
            placement="bottom-end"
            dismiss={{
                itemPress: false,
            }}
        >
            <MenuHandler disabled={!data}>
                <Button disabled={!data} color='white' className='text-[12px] tab:text-[16px] h-[40px] tab:h-[50px] px-[12px] tab:px-[24px] bg-gray-50 w-auto tab:w-60 hover:bg-gray-50  hover:border-gray-300  rounded flex items-center gap-[8px] justify-between'>
                    {t(`month_${activeDate.month}`)} - {activeDate.year}
                    {
                        data ?
                            <SelectIcon />
                            :
                            <Spinner className="h-4 w-4" />
                    }
                </Button>
            </MenuHandler>
            <MenuList className='w-[calc(100%-32px)] tab:w-[500px] p-[24px] border-2 border-gray rounded-[8px] tab:rounded-[16px] shadow-none *:!outline-0'>
                <p className='mb-[8px] text-gray-700'>
                    {t('year')}
                </p>
                <div className='grid grid-cols-3 gap-[12px] mb-[24px]'>
                    {
                        data &&
                        Object.keys(data).map(year => (
                            <Button
                                key={year}
                                color='white'
                                className={`transition-all py-[10px] ${activeYear === Number(year) ? 'cursor-default bg-black border-black !text-white hover:!bg-black' : ''}`}
                                onClick={() => {
                                    if (activeYear !== Number(year)) {
                                        setActiveMonth('')
                                        setActiveYear(Number(year))
                                    }
                                    if (activeDate.year === Number(year)) {
                                        setActiveMonth(activeDate.month)
                                    }
                                }}
                            >
                                <span className='flex justify-around items-center gap-[4px] relative text-[14px] font-normal'>
                                    <CheckIcon className={`hidden tab:block opacity-0 transition-all ${activeYear === Number(year) && 'opacity-100'} `} />
                                    {year}
                                    <CheckIcon className={`hidden tab:block opacity-0`} />
                                </span>
                            </Button>
                        ))
                    }
                </div>
                <p className='mb-[8px] text-gray-700'>
                    {t('month')}
                </p>
                <div className='grid grid-cols-3 gap-[12px]'>
                    {
                        data &&
                        activeYear &&
                        isIterableArray(data[activeYear]) &&
                        data[activeYear].map((item, key) => (
                            <Button
                                key={key}
                                color='white'
                                className={`transition-all bg-gray-50 py-[8px] px-[12px] rounded-[8px] ${activeMonth === item.month ? 'cursor-default bg-black border-black !text-white hover:!bg-black' : ''}`}
                                onClick={() => {
                                    if (activeMonth !== item.month) {
                                        setActiveMonth(item.month)
                                        setActiveDate({
                                            year: Number(item.year),
                                            month: item.month
                                        })
                                        setIsOpen(false)
                                    }
                                }}
                            >
                                <span className='flex justify-around items-center gap-[4px] relative text-[14px] font-normal'>
                                    <CheckIcon className={`hidden tab:block opacity-0 transition-all ${activeMonth === item.month && 'opacity-100'} `} />
                                    {t(`month_${item.month}`)}
                                    <CheckIcon className={`hidden tab:block opacity-0`} />
                                </span>
                            </Button>
                        ))


                    }
                </div>
            </MenuList>
        </Menu>
    )
}

export default DateSelect
