const theme = {
    button: {
        defaultProps: {
            variant: "filled",
            size: "md",
            color: "blue",
            fullWidth: false,
            ripple: false,
            className: "font-normal",
        },
        styles: {
            base: {
                initial: {
                    textTransform: "none",
                },
            },
            sizes: {
                sm: {
                    fontSize: "text-xs",
                    py: "!py-[6px]",
                    px: "!px-[6px]",
                    borderRadius: "!rounded-[8px]",
                },
                md: {
                    fontSize: "text-[16px]",
                    py: "py-[9px]",
                    px: "px-[16px]",
                    borderRadius: "rounded",
                },
                lg: {
                    fontSize: "text-sm",
                    py: "py-3.5",
                    px: "px-7",
                    borderRadius: "rounded",
                },
            },
            variants: {
                filled: {
                    white: {
                        className: 'border-2 border-gray-200 py-[11px] px-[24px]',
                        borderRadius: 'rounded-full',
                        backgroud: "bg-white",
                        color: "text-gray-700",
                        shadow: "",
                        hover: "hover:bg-gray-100 hover:shadow-none",
                        focus: "focus:opacity-1 focus:shadow-none focus-visible:outline-0",
                        active: "active:opacity-[0.9] active:shadow-none",
                        disabled: 'disabled:bg-gray-100 disabled:text-gray-300 disabled:cursor-not-allowed'
                    },
                    blue: {
                        backgroud: "bg-blue",
                        color: "text-white",
                        shadow: "",
                        hover: "hover:bg-blue-400 hover:shadow-none",
                        focus: "focus:opacity-1 focus:shadow-none",
                        active: "active:opacity-[0.9] active:shadow-none",
                        disabled: 'disabled:bg-gray-100 disabled:text-gray-300 disabled:cursor-not-allowed'
                    },
                    black: {
                        className: 'py-[11px] px-[24px]',
                        borderRadius: 'rounded-full',
                        backgroud: "bg-gray-700",
                        color: "text-white",
                        shadow: "",
                        hover: "hover:bg-black",
                        focus: "focus:opacity-1 focus:shadow-none",
                        active: "active:opacity-[0.9] active:shadow-none",
                        disabled: 'disabled:bg-gray-700 disabled:text-gray-300 disabled:cursor-not-allowed'
                    },
                    red: {
                        className: 'py-[11px] px-[24px]',
                        borderRadius: 'rounded-full',
                        backgroud: "bg-red-500",
                        color: "text-white",
                        shadow: "",
                        hover: "hover:bg-red-700",
                        focus: "focus:opacity-1 focus:shadow-none",
                        active: "active:opacity-[0.9] active:shadow-none",
                        disabled: 'disabled:bg-gray-700 disabled:text-gray-300 disabled:cursor-not-allowed'
                    }

                }
            }
        }
    },
    typography: {
        defaultProps: {
            color: "gray-800",
        },
        styles: {
            variants: {
                h1: {
                    fontSize: "text-[32px] tab:text-48 lap:text-56 des:text-72",
                    fontWeight: "font-bold",
                    lineHeight: "leading-[110%]",
                },
                h2: {
                    fontSize: "text-[48px]",
                    fontWeight: "font-bold",
                    lineHeight: "leading-[110%]",
                },
                h3: {
                    fontSize: "text-[24px] mob:text-[24px] lap:text-[40px]",
                    fontWeight: "font-normal",
                    lineHeight: "leading-[110%]",
                    color: "text-gray-800",
                },
                h4: {
                    fontSize: "text-16 md:text-24",
                    fontWeight: "font-normal",
                    lineHeight: "leading-[110%]",
                },
                lead: {
                    fontSize: "text-[16px]",
                    fontWeight: "font-normal",
                    lineHeight: "leading-[140%]",
                },
            },
            colors: {
                inherit: {
                    color: "text-inherit",
                },
                current: {
                    color: "text-gray-900",
                },
                gray: {
                    color: 'text-gray'
                },
                blue: {
                    color: 'text-blue'
                }
            },
        }
    },
    menu: {
        defaultProps: {
            placement: "bottom",
            offset: 5,
            dismiss: {
                isRequired: true,
                itemPress: true
            },
            animate: {
                unmount: {},
                mount: {},
            },
            lockScroll: false,
        },
        styles: {
            base: {
                menu: {
                    bg: "bg-white",
                    minWidth: "min-w-[180px]",
                    p: "p-0",
                    border: "border-0",
                    borderRadius: "rounded",
                    boxShadow: "shadow-list",
                    color: "text-gray-700",
                },
                item: {
                    initial: {
                        bg: "hover:!bg-gray-50 focus:bg-gray-100 focus:bg-opacity-80 active:bg-gray-100 active:bg-opacity-80",
                        color: "hover:!text-gray-700 focus:text-gray-800 active:text-gray-800",
                        borderRadius: 'rounded-0',
                        padding: '10px 16px'
                    },
                },
            },
        },
    },
    input: {
        styles: {
            variants: {
                static: {
                    base: {
                        input: {
                            borderWidth: "border-2 border-gray-200 rounded-[8px] focus:border-blue-400",
                            borderColor: "placeholder-shown:border-blue-gray-200",
                            color: 'text-gray-600',
                        },
                        label: {
                            fontSize: "peer-placeholder-shown:text-[18px]",
                            color: 'text-gray-800',
                            position: "-top-[2rem]",
                            after: {
                                display: 'after:hidden'
                            },
                        },
                    },
                    sizes: {
                        md: {
                            container: {
                                height: "height-[56px]",
                            },
                            input: {
                                fontSize: "text-[18px]",
                                pt: "px-[16px]",
                                pb: "py-[16px]",
                            },
                            label: {
                                lineHeight: "peer-placeholder-shown:leading-tight",
                            },
                            icon: {
                                width: "w-5",
                                height: "h-5",
                            },
                        },
                    },

                },
            },
        },
    },
    drawer: {
        defaultProps: {
            // size: 640,
        },
        styles: {
            base: {
                drawer: {
                    position: "fixed",
                    borderRadius: 'rounded-[24px_24px_0_0] lap:rounded-24',
                    zIndex: "z-[9999]",
                    pointerEvents: "pointer-events-auto",
                    backgroundColor: "bg-gray-50",
                    boxSizing: "box-border",
                    width: "w-full",
                    height: "h-[70%] max-h-auto lap:!h-auto",
                    boxShadow: "shadow-none",
                    className: 'lap:!top-[12px] lap:!bottom-[12px]  !px-[16px] tab:!px-[32px] !py-[16px] tab:!py-[24px]'
                },
                overlay: {
                    position: "fixed",
                    inset: "inset-0",
                    width: "w-full",
                    height: "h-full",
                    pointerEvents: "pointer-events-auto",
                    zIndex: "z-[9995]",
                    backgroundColor: "bg-gray-800",
                    backgroundOpacity: "bg-opacity-60",
                    backdropBlur: "backdrop-blur-none",
                },
            },
        },
    },
    checkbox: {
        defaultProps: {
            ripple: false
        },
        styles: {
            base: {
                root: {
                    display: "flex",
                },
                input: {
                    before: {
                        bg: "before:bg-gray-100",
                        width: "before:w-10",
                        height: "before:h-10",
                        opacity: "before:opacity-0 hover:before:opacity-5",
                        // display: 'hidden'
                    }
                }
            },
        },
    },
    select: {
        defaultProps: {
            color: "gray",
        },
        styles: {
            base: {
                container: {
                    color: "text-gray-700",
                    borderRadius: 'rounded',
                    // outline: '!outline-gray-200',
                    // border: 'border-[2px] !border-gray-200',
                    // disabled: "disabled:bg-gray-200 disabled:border-0"
                },
                select: {
                    color: "text-gray-700",
                    borderRadius: 'rounded',
                    outline: 'outline-0',
                    // border: 'border-[2px] !border-gray-200',
                    // disabled: "disabled:bg-gray-200 disabled:border-0"
                },
                label: {
                    color: 'text-gray-700'
                }
            },
            variants: {
                outlined: {
                    colors: {
                        label: {
                            "gray": {
                                close: {
                                    color: "text-gray-700",
                                    before: "before:border-transparent",
                                    after: "after:border-transparent",
                                },
                                open: {
                                    color: "text-gray-500",
                                    before: "before:border-blue-gray-500",
                                    after: "after:border-transparent",
                                },
                                withValue: {
                                    color: "textы-gray-800",
                                    before: "before:border-blue-gray-200",
                                    after: "after:border-transparent",
                                },
                            }
                        },
                        select: {
                            "gray": {
                                close: {
                                    borderColor: "border-gray-200",
                                    after: "after:border-transparent",
                                },
                                open: {
                                    borderColor: "border-gray-200",
                                    borderTopColor: "border-t-transparent",
                                    after: "after:border-transparent",
                                },
                                withValue: {
                                    borderColor: "border-gray-200",
                                    borderTopColor: "border-t-transparent",
                                    after: "after:border-transparent",
                                },
                            }
                        }
                    }
                }
            }
        }
    }
}
export default theme