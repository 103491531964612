import { Button } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

export const DownloadExcelButton = () => {

    const { t } = useTranslation()
    const text = t('consumption.download_excel')

    return (
        <Button color='black' className="w-full tab:w-auto">
            {text}
        </Button>
    )
}