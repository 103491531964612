import { useState } from 'react'
import { Typography } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import ArrowAccordionIcon from '../../images/icons/ArrowAccordionIcon'
import { useForm } from 'react-hook-form'
import { filtersActsTypes, getBalancesByService } from '../tableHelpers/tableConfig'
import { useGetContractPeriodQuery } from '../../store/auth/authService'
import { useParams } from 'react-router-dom'
import { ActFormInput } from './ActsPage.types'
import FilterDateItem from '../tableHelpers/filters/FilterDateItem'
import FilterSearch from '../tableHelpers/filters/FilterSearch'
import FilterPeriod from '../tableHelpers/filters/FilterPeriod'
import FilterItem from '../tableHelpers/filters/FilterItem'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import MobileFilters from './mobile/MobileFilters'


const ActsFilters = (props: any) => {

    const { fetchPage, isLoadingFetch, onSearch } = props

    const { t } = useTranslation()

    const { param_contract_id } = useParams()
    const { data } = useGetContractPeriodQuery({
        contract_id: param_contract_id,
        endOffset: 3
    })

    const defaultState: ActFormInput = {
        periodFrom: '',
        periodTo: '',
        type: [],
        balance: [],
        date_payment: [],
    }
    const {
        control,
        reset,
        getValues,
    } = useForm<ActFormInput>({
        defaultValues: defaultState,
    })

    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const [filtersState, setFiltersState] = useState<ActFormInput>(defaultState);

    const handleCloseFilter = () => {
        const values = getValues()

        setFiltersState(values)

        if (JSON.stringify(values) !== JSON.stringify(filtersState)) {
            // fetch page with new filters 
            fetchPage(values)
        }
    }
    const handleClearFilter = () => { 
        setFiltersState(defaultState)
        reset()
        if (JSON.stringify(defaultState) !== JSON.stringify(filtersState)) {
            // fetch page with new filters 
            fetchPage(defaultState)
        }
    }


    return (
        <>
            <div className='tab:!hidden'>
                <MobileFilters
                    defaultState={defaultState}
                    handleCloseFilters={handleCloseFilter}
                    handleClearFilter={handleClearFilter}
                    control={control}
                    onSearch={onSearch}
                    datesData={data}
                    filtersState={filtersState}
                />
            </div>
            <div className='
                hidden tab:flex justify-between items-center flex-wrap gap-[4px] tab:gap-[16px]  mb-[24px] lap:mb-[32px]
            '>
                <div className='flex items-center gap-[8px]'>
                    <Typography className='text-gray-700'>
                        {t('filters.period')}
                    </Typography>
                    <FilterDateItem
                        isLoadingFetch={isLoadingFetch}
                        placement={'bottom-start'}
                        control={control}
                        fieldName={'periodFrom'}
                        label={t("filters.period_from")}
                        datesData={data}
                        handleCloseFilter={handleCloseFilter}
                        isDisabled={(date: string) => {
                            return moment(filtersState.periodTo, 'MM-YYYY').isBefore(moment(date, 'MM-YYYY'))
                        }}
                    />
                    <span className='rotate-90'>
                        <ArrowAccordionIcon />
                    </span>
                    <FilterDateItem
                        isLoadingFetch={isLoadingFetch}
                        placement={'bottom-end'}
                        control={control}
                        fieldName={'periodTo'}
                        label={t("filters.period_to")}
                        datesData={data}
                        handleCloseFilter={handleCloseFilter}
                        isDisabled={(date: string) => {
                            return moment(filtersState.periodFrom, 'MM-YYYY').isAfter(moment(date, 'MM-YYYY'))
                        }}
                    />
                </div>
                <div className='flex items-center gap-[4px] ml-auto'>
                    <FilterItem
                        isLoadingFetch={isLoadingFetch}
                        control={control}
                        label={t('filters.act_type')}
                        fieldName={'type'}
                        defaultValue={defaultState.type}
                        filtersData={filtersActsTypes}
                        handleCloseFilter={handleCloseFilter}
                    />
                    <FilterItem
                        isLoadingFetch={isLoadingFetch}
                        control={control}
                        label={t('filters.balance_type')}
                        fieldName={'balance'}
                        defaultValue={defaultState.balance}
                        filtersData={getBalancesByService(service_id)}
                        handleCloseFilter={handleCloseFilter}
                    />
                    <FilterPeriod
                        isLoadingFetch={isLoadingFetch}
                        control={control}
                        label={t('filters.payment_due')}
                        fieldName={'date_payment'}
                        defaultValue={defaultState.date_payment}
                        filterStateValue={filtersState.date_payment}
                        handleCloseFilter={handleCloseFilter}
                        datesData={data}
                    />
                    <FilterSearch
                        onSearch={onSearch}
                    />
                </div>
            </div>
        </>
    )
}

export default ActsFilters
