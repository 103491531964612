import { Button, Typography } from '@material-tailwind/react'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PageLayout from '../../../layouts/pagesLayouts/PageLayout'
import { useViewDeclaredPowerQuery } from '../../../store/auth/authService'
import { setContractId, setServiceId } from '../../../store/service/contractSlice'
import { AppDispatch } from '../../../store/store'
import { isIterableArray } from '../../../utils/utils'
import MatrixDays from '../matrix/MatrixDays'
import TkoInfoCard from '../TkoInfoCard'
import { DownloadExcelButton } from '../DownloadExcelButton'

const ViewDeclaredPower = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const { param_contract_id, param_cons_id, param_year, param_month } = useParams()


    const dispatch = useDispatch<AppDispatch>()

    const { data, isLoading, error } = useViewDeclaredPowerQuery({
        contract_id: Number(param_contract_id),
        id: Number(param_cons_id)
    })
 

    useEffect(() => {
        dispatch(setContractId(Number(param_contract_id)))
    }, [param_contract_id]);

    useEffect(() => {
        if (data?.service_id) dispatch(setServiceId(Number(data.service_id)))
    }, [data]);


    const {
        control,
    } = useForm<any>()


    const handleNavigateBack = () => {
        if (location.state?.pathname) navigate(location.state.pathname + (location.state?.search ? location.state.search : ''))
        else navigate(`/consumptions/${param_contract_id}`)
    }

    return (
        <PageLayout title={
            <Typography variant='h3' className='text-gray-800 text-center mb-[32px]'>
                {t('declared.view_declared_power_title')}
            </Typography>
        }>
            <Helmet>
                <title>
                    {t('declared.view_declared_power_title')}
                </title>
            </Helmet>
            {
                data?.tko && <TkoInfoCard tko={data.tko} />
            }

            {
                isIterableArray(data?.matrix) &&
                <MatrixDays
                    control={control}
                    data={data.matrix}
                    isEditAccess={false}
                    DownloadExcelButton={DownloadExcelButton}
                    amount={data?.amount}
                    handleNavigateBack={handleNavigateBack}
                />
            }
        </PageLayout>
    )
}

export default ViewDeclaredPower