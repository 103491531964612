import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Typography,
} from "@material-tailwind/react";
import axios from "axios";
import { ReactPortal, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { errorToast, successToast } from "../../components/toasts/toasts";
import { baseApi } from "../../ConfigApi";
import CloseDrawerIcon from "../../images/icons/CloseDrawerIcon";
import { RootState } from "../../store/store";
import { TypeTkoInfo } from "./_types";

const ModalEditName = ({ open, handleConfirm, handleClose, modalValue }: {
    open: boolean,
    handleConfirm: () => void,
    handleClose: () => void,
    modalValue: TypeTkoInfo
}): ReactPortal | null => {

    const { t } = useTranslation()

    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');

    const defaultName = modalValue?.name

    useEffect(() => {
        if (defaultName && open) setInputValue(defaultName)
        return () => {
            setInputValue('')
        };
    }, [open, defaultName]);

    const saveName = () => {
        if (service_id) {
            const url = service_id === 1 ? '/api/ca/consumption/rename-tko' : service_id === 2 ? '/api/ca/consumption-gas/rename-tko' : ''
            setIsLoadingSave(true)
            axios.post(`${baseApi}${url}`, {
                tko_id: modalValue.id,
                new_name: inputValue
            })
                .then((response: any) => {
                    if (response.data.res === 'error') {
                        errorToast({
                            title: t('consumption.consumption_title'),
                            text: t('consumption.error_on_change_name')
                        })
                    }
                    else {
                        successToast({
                            title: t('consumption.change_name_success')
                        })
                        handleConfirm()
                    }
                })
                .catch(error => {
                    console.error(error);
                    errorToast({
                        title: t('consumption.consumption_title'),
                        text: t('consumption.error_on_change_name')
                    })
                })
                .finally(() => {
                    setIsLoadingSave(false)
                })
        }
    }

    return (
        createPortal(
            <>
                <Dialog open={open} handler={handleClose} className='!min-w-0 !max-w-[640px] bg-white p-[24px] tab:p-[32px] rounded-[16px] '>

                    <DialogBody className="p-0">
                        <div className="flex items-center justify-between mb-[32px]">
                            <Typography variant="lead" className="text-[18px] tab:text-[24px] text-gray-800 font-medium ">
                                {t('consumption.rename_tko')}
                            </Typography>
                            <div onClick={handleClose} className="rounded-full w-[32px] h-[32px] transition-all flex items-center justify-center active:bg-gray-200 hover:bg-gray-100 cursor-pointer">
                                <CloseDrawerIcon />
                            </div>
                        </div>
                        <Typography variant="lead" className="mb-[8px] text-gray-700">
                            {t('consumption.tko_text_title')}
                        </Typography>
                        <div className="flex flex-col tab:flex-row item-center gap-[10px]">
                            <input
                                value={inputValue}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
                                className='w-full grow border-2 border-gray-200 rounded w-[240px] h-[50px] px-[16px] text-gray-700
                                    hover:bg-gray-50 hover:border-gray-300 outline-gray-400
                                    focus:bg-white focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                                '
                                placeholder={defaultName || t('consumption.tko_name_placeholder')}
                            />
                            <Button
                                color='black'
                                loading={isLoadingSave}
                                disabled={isLoadingSave || defaultName === inputValue}
                                onClick={saveName}
                            >
                                {t('confirm')}
                            </Button>

                        </div>
                    </DialogBody>
                </Dialog>
            </>,
            document.getElementById('root')!
        )
    )
}

export default ModalEditName
