import { useSelector } from "react-redux"
import { RootState } from "../../store/store"

const PageLayout = ({ children, title, contentClassName }: any) => {

    const service_id = useSelector((state: RootState) => state.contract.service_id)

    return (
        <div className={`px-[16px] tab:px-[32px] lap:px-[48px] des:px-[96px] py-[48px] mx-auto service_id_${service_id}`}>
            {
                title &&
                <div className='mb-[64px]'>
                    {title}
                </div>
            }
            <div className={`page-content ${contentClassName || ''}`}>
                {children}
            </div>
            {
                // error && 'error text & btn try again'
            }
        </div>
    )
}

export default PageLayout