import React, { useEffect, useState } from 'react'
import { isIterableArray } from '../../../utils/utils'
import ItemDayGraph from './ItemDayGraph';

const GasDaysGraphs = ({ fact_matrix, gas_matrix, power_matrix }: {
    fact_matrix: number[],
    gas_matrix: number[],
    power_matrix: number[]
}) => {

    const [daysData, setDaysData] = useState<number>(0);

    useEffect(() => {
        if (isIterableArray(fact_matrix)) setDaysData(fact_matrix.length)
    }, [fact_matrix]);
    return (
        daysData > 0 ?
            <div className='grid grid-cols-2 mob:grid-cols-3 tab:grid-cols-4 lap:grid-cols-7 gap-[16px]' >
                {
                    Array.from(Array(daysData).keys()).map((_, key: number) => (
                        <ItemDayGraph
                            key={key}
                            isWeekend={(key + 1) % 7 === 0}
                            day={key + 1}
                            fact={fact_matrix[key]}
                            gas={gas_matrix[key]}
                            power={power_matrix[key]}
                        />
                    ))
                }
            </div >
            :
            <></>
    )
}

export default GasDaysGraphs