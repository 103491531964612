import { Button, Dialog, DialogBody, Typography } from '@material-tailwind/react';
import React, { ReactPortal, useEffect, useState } from 'react'
import { createPortal } from 'react-dom';
import CloseDrawerIcon from '../../images/icons/CloseDrawerIcon';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { baseApi } from '../../ConfigApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { errorToast, successToast } from '../../components/toasts/toasts';

const ChangeAvatarModal = ({ open, handleConfirm, handleClose, modalValue }: {
    open: boolean,
    handleConfirm: any,
    handleClose: () => void,
    modalValue?: any
}): ReactPortal | null => {

    const { t } = useTranslation()

    const { token } = useSelector((state: RootState) => state.auth)

    const [isLoadingSave, setIsLoadingSave] = useState(false)

    const [fileForSend, setFileForSend] = useState<any>(null);
    const [file, setFile] = useState<any>(null)
    const [fileName, setFileName] = useState(t('profile.no_file_changed'))

    const saveAvatar = () => {
        setIsLoadingSave(true)
        axios.post(`${baseApi}/api/cabinet/profile-updateavatar`, {
            file: fileForSend
        }, {
            headers: {
                'Authorization': token,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response: any) => {
                if (response.data.res === 'Error') {
                    errorToast({
                        title: t('profile.profile_avatar'),
                        text: t('profile.error_on_upload_avatar')
                    })
                }
                else {
                    successToast({
                        title: t('profile.profile_avatar'),
                        text: t('profile.success_upload_avatar')
                    })
                    handleClose()
                    handleConfirm()
                }
            })
            .catch(error => {
                console.error(error);
                errorToast({
                    title: t('profile.profile_avatar'),
                    text: t('profile.error_on_upload_avatar')
                })
            })
            .finally(() => {
                setIsLoadingSave(false)
            })
    }

    useEffect(() => {
        return () => {
            setFile(null)
            setFileForSend(null)
            setFileName(t('profile.no_file_changed'))
        }
    }, [])


    return (

        createPortal(
            <>
                <Dialog
                    handler={handleClose} open={open} className='!transition-all !animate-none !min-w-0 !max-w-[640px] bg-white p-[24px] tab:p-[32px] rounded-[16px] '>
                    <DialogBody className="p-0 !duration-100 ">
                        <div className="flex flex-col gap-[32px]">
                            <div className="flex items-center justify-between ">
                                <Typography variant="lead" className="text-[24px] text-gray-800 font-medium ">
                                    {t('profile.new_avatar')}
                                </Typography>
                                <div onClick={handleClose} className="rounded-full w-[32px] h-[32px] transition-all flex items-center justify-center active:bg-gray-200 hover:bg-gray-100 cursor-pointer">
                                    <CloseDrawerIcon />
                                </div>
                            </div>

                            <form className="flex items-center space-x-6">
                                {
                                    file &&
                                    <div className="shrink-0">
                                        <img className="h-16 w-16 object-cover rounded-full border-2 border-gray-200" src={file} alt={t('UserPhoto')} />
                                    </div>
                                }

                                <div className="flex flex-row items-center">
                                    <input
                                        type="file"
                                        id="custom-input"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const file = e.target?.files?.[0]

                                            if (file) {
                                                setFileForSend(file)
                                                const reader = new FileReader();
                                                reader.onloadend = () => {
                                                    console.log(reader.result);
                                                    setFile(reader.result)
                                                    setFileName(file.name)
                                                };
                                                reader.readAsDataURL(file);
                                            }
                                        }}
                                        hidden
                                    />
                                    <label
                                        htmlFor="custom-input"
                                        className="block text-sm text-gray-700 mr-4 py-2 px-4
                                            rounded-md border-0 text-sm font-semibold bg-gray-100 transition-all
                                             hover:bg-gray-200 cursor-pointer"
                                    >
                                        {t('profile.choise_avatar')}
                                    </label>
                                    <label className="text-sm text-slate-500">{fileName}</label>
                                </div>
                                {/* <label className="block">
                                    <span className="sr-only">
                                        {t('profile.choise_avatar')}
                                    </span>
                                    <input type="file"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            console.log(e.target?.files?.[0])
                                            const file = e.target?.files?.[0]

                                            if (file) {
                                                const reader = new FileReader();
                                                reader.onloadend = () => {
                                                    console.log(reader.result);
                                                    setFile(reader.result)
                                                };
                                                reader.readAsDataURL(file);
                                            }
                                        }}
                                        className="block w-full text-sm text-slate-500
                                        file:mr-4 file:py-2 file:px-4
                                        file:rounded-full file:border-0
                                        file:text-sm file:font-semibold
                                        file:bg-violet-50 file:text-violet-700
                                        hover:file:bg-violet-100
                                    "/>
                                </label> */}
                            </form>
                            <div className="flex item-center justify-between">
                                <Button color="white" onClick={handleClose}>
                                    {t('cancel')}
                                </Button>
                                <Button
                                    color="black"
                                    onClick={saveAvatar}
                                    loading={isLoadingSave}
                                    disabled={isLoadingSave || !fileForSend}
                                >
                                    {t('confirm')}
                                </Button>
                            </div>
                        </div>
                    </DialogBody>
                </Dialog>
            </>,
            document.getElementById('root')!
        )
    )
}

export default ChangeAvatarModal