import { Typography } from '@material-tailwind/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ItemDayGraph = ({ day, fact, gas, power, isWeekend }: { day: number, fact: number, gas: number, power: number, isWeekend: boolean }) => {

    const { t } = useTranslation()

    return (
        // ${item?.active ? 'border-gray-100' : 'border-gray-50'}
        <div className={`rounded-[12px] tab:rounded-[16px] p-[8px] mob:p-[16px] des:p-[24px] flex flex-col gap-[4px] mob:gap-[8px] border-2 border-gray-100 animate-appear transition-all cursor-pointer hover:bg-gray-50 ${isWeekend ? 'bg-gray-50 hover:bg-gray-100' : ''}`}
        >
            <Typography
                variant='lead'
                className={`text-[14px] text-gray-700 font-normal
           `}
            >
                {day}
            </Typography>
            <div className="flex flex-col gap-[8px]">
                <Typography
                    variant='lead'
                    className={`text-blue-600 font-bold`}
                >
                    {fact}
                    {' '}
                    <span className={`text-gray-600 font-medium text-[14px] `}>
                        {t('cubicMeter')}
                    </span>
                </Typography>
                <Typography
                    variant='lead'
                    className={`text-orange-500 font-bold`}
                >
                    {gas}
                    {' '}
                    <span className={`text-gray-600 font-medium text-[14px] `}>
                        {t('cubicMeter')}
                    </span>
                </Typography>
                <Typography
                    variant='lead'
                    className={`text-gray-500 font-bold`}
                >
                    {power}
                    {' '}
                    <span className={`text-gray-600 font-medium text-[14px] `}>
                        {t('cubicMeter')}
                    </span>
                </Typography>
            </div>
        </div>
    )
}

export default ItemDayGraph
