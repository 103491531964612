import React, { useEffect, useState } from 'react'
import { useViewConsumptionQuery, useViewSaldoQuery } from '../../store/auth/authService'
import PageLayout from '../../layouts/pagesLayouts/PageLayout'
import { Typography } from '@material-tailwind/react'
import { isIterableArray } from '../../utils/utils'
import moment from 'moment'
import { useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AppDispatch, RootState } from '../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { setContractId } from '../../store/service/contractSlice'
import { Helmet } from 'react-helmet'
import OverlayUnTouchable from '../../layouts/pagesLayouts/OverlayUnTouchable'
import ViewSaldo from './ViewSaldo'

const AnalyticsPage = () => {


    const { t } = useTranslation()
    // const dataCons = useViewConsumptionQuery<any>({
    //     contract_id: 2,
    //     periodFrom: "10-2021",
    //     periodTo: "10-2023"
    // })

    // console.log(dataCons)

    // const service_id = useSelector((state: RootState) => state.contract.service_id)


    return (
        <PageLayout>
            <Helmet>
                <title>
                    {t('consumption.consumption_title')}
                </title>
            </Helmet>
            {/* <Filters
                listTko={listTkoQuery?.data}
                value={{
                    periodFrom: sendForm.periodFrom,
                    periodTo: sendForm.periodTo,
                    tko: sendForm.tko
                }}
                isLoadingFetch={listTkoQuery?.isFetching}
                fetchPage={setFormForFetch}
            /> */}

            {/* <ViewSaldo /> */}
        </PageLayout>
    )
}

export default AnalyticsPage
