import { Button, Typography } from "@material-tailwind/react"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import PageLayout from "../../../layouts/pagesLayouts/PageLayout"
import { useViewDeclaredPowerAllQuery } from "../../../store/auth/authService"
import { setContractId, setServiceId } from "../../../store/service/contractSlice"
import { AppDispatch } from "../../../store/store"
import { isIterableArray } from "../../../utils/utils"
import TkoInfoCard from "../TkoInfoCard"
import MatrixDays from "../matrix/MatrixDays"
import { useForm } from "react-hook-form"
import { Helmet } from "react-helmet"
import { DownloadExcelButton } from "../DownloadExcelButton"


const ViewDeclaredPowerAll = () => {

    const { t } = useTranslation()
    const { param_contract_id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch<AppDispatch>()

    let [searchParams, setSearchParams] = useSearchParams();

    const tkos_param = searchParams.get('tkos')

    const tkosParamArr = tkos_param ? tkos_param.split(',').map(i => parseInt(i)) : []

    const { data, isLoading, isFetching, error } = useViewDeclaredPowerAllQuery({
        contract_id: Number(param_contract_id),
        arr_power: isIterableArray(tkosParamArr) ? tkosParamArr : [],
    })



    useEffect(() => {
        dispatch(setContractId(Number(param_contract_id)))
    }, [param_contract_id]);

    useEffect(() => {
        if (data?.service_id) dispatch(setServiceId(Number(data.service_id)))
    }, [data]);



    const {
        control,
    } = useForm<any>()

    const handleNavigateBack = () => {
        if (location.state?.pathname) navigate(location.state.pathname + (location.state?.search ? location.state.search : ''))
        else navigate(`/consumptions/${param_contract_id}`)
    }

    return (
        <PageLayout title={
            <Typography variant='h3' className='text-gray-800 text-center mb-[32px]'>
                {t('declared.view_declared_power_all_title')}
            </Typography>
        }>
            <Helmet>
                <title>
                    {t('declared.view_declared_power_all_title')}
                </title>
            </Helmet>
            {data?.tko && <TkoInfoCard tko={data.tko} />}
            {
                isIterableArray(data?.matrix) &&
                <MatrixDays
                    control={control}
                    data={data.matrix}
                    isEditAccess={false}
                    DownloadExcelButton={DownloadExcelButton}
                    amount={data?.total}
                    handleNavigateBack={handleNavigateBack}
                />
            }
        </PageLayout>
    )
}

export default ViewDeclaredPowerAll