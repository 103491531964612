import { Button } from '@material-tailwind/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckIcon from '../../../images/icons/CheckIcon'
import { isIterableArray } from '../../../utils/utils'
import { DateSelectType } from '../../contract/ContractPage.types'

type propTypes = {
    value: string,
    // activeYear: any,
    // setActiveYear: any,
    // activeMonth: any,
    // setActiveMonth: any,
    datesData: DateSelectType,
    // disabledDate?: string,
    isDisabled: (date: string) => boolean,
    handleSelectDate: any
}
const MonthYearSelect = (props: propTypes) => {

    const { t } = useTranslation()
    const {
        value,
        datesData,
        // activeYear,
        // setActiveYear,
        // activeMonth,
        // setActiveMonth,
        // disabledDate,
        isDisabled,
        handleSelectDate
    } = props

    const defaultMonth = ''
    const defaultYear = Number(moment().format('YYYY'))

    const [activeYear, setActiveYear] = useState<any>(value ? Number(moment(value, 'MM-YYYY').format('YYYY')) : defaultYear);
    const [activeMonth, setActiveMonth] = useState<string>(moment(value, 'MM-YYYY').format('MM') || defaultMonth);

    useEffect(() => {
        if (!value) {
            setActiveMonth('')
        }
    }, [value]);

    return (
        <div>
            <p className='mb-[8px] text-gray-700 hidden tab:block'>
                {t('year')}
            </p>
            <div className='grid grid-cols-3 gap-[6px] tab:gap-[12px] mb-[12px] tab:mb-[24px]'>
                {
                    datesData &&
                    Object.keys(datesData).map(year => (
                        <Button
                            key={year}
                            color='white'
                            className={`transition-all py-[10px] ${activeYear === Number(year) ? 'cursor-default !bg-black border-black !text-white hover:!bg-black' : ''}`}
                            onClick={() => {
                                if (activeYear !== Number(year)) {
                                    setActiveMonth('')
                                    setActiveYear(Number(year))
                                }
                                // if (activeDate.year == Number(year)) {
                                //     setActiveMonth(activeDate.month)
                                // }
                            }}
                        >
                            <span className='flex justify-around items-center gap-[4px] relative text-[14px] font-normal'>
                                <CheckIcon className={`hidden tab:block opacity-0 transition-all ${activeYear === Number(year) && 'opacity-100'} `} />
                                {year}
                                <CheckIcon className={`hidden tab:block opacity-0`} />
                            </span>
                        </Button>
                    ))
                }
            </div>
            <p className='mb-[8px] text-gray-700 hidden tab:block'>
                {t('month')}
            </p>
            <div className='grid grid-cols-4 tab:grid-cols-3 gap-[6px] tab:gap-[12px]'>
                {
                    datesData &&
                    activeYear &&
                    isIterableArray(datesData[activeYear]) &&
                    datesData[activeYear].map((item, key) => (
                        <Button
                            disabled={isDisabled(`${item.month}-${activeYear}`)}
                            key={key}
                            color='white'
                            className={`transition-all bg-white tab:bg-gray-50 py-[8px] px-[12px] rounded-[8px] ${activeMonth === item.month ? 'cursor-default !bg-black border-black !text-white hover:!bg-black' : ''}`}
                            onClick={(e) => {
                                e.stopPropagation()
                                if (activeMonth !== item.month) {
                                    setActiveMonth(item.month)
                                    // setActiveDate(item)
                                    handleSelectDate(`${item.month}-${activeYear}`)
                                }
                            }}
                        >
                            <span className='flex justify-around items-center gap-[4px] relative text-[14px] font-normal'>
                                <CheckIcon className={`hidden tab:block opacity-0 transition-all ${activeMonth === item.month && 'opacity-100'} `} />
                                <span className='tab:hidden'> {t(`short_month_${item.month}`)}</span>
                                <span className='hidden tab:block'> {t(`month_${item.month}`)}</span>
                                <CheckIcon className={`hidden tab:block opacity-0`} />
                            </span>
                        </Button>
                    ))
                }
            </div>
        </div>
    )
}

export default MonthYearSelect
