import { Button, Dialog, DialogBody, IconButton, List, ListItem, ListItemSuffix, Typography } from '@material-tailwind/react'
import React, { ReactPortal, useState } from 'react'
import { createPortal } from 'react-dom'
import CloseDrawerIcon from '../../../images/icons/CloseDrawerIcon'
import { useTranslation } from 'react-i18next'
import { isIterableArray } from '../../../utils/utils'

function TrashIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-5 w-5"
        >
            <path
                fillRule="evenodd"
                d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                clipRule="evenodd"
            />
        </svg>
    );
}
const ModalAddFiles = ({ open, handleClose, setFilesListForSend
}: {
    open: boolean,
    handleClose: () => void,
    setFilesListForSend: any
}): ReactPortal | null => {

    const { t } = useTranslation()


    const [filesList, setFilesList] = useState<any>([]);

    // const [fileForSend, setFileForSend] = useState<any>(null);
    // const [file, setFile] = useState<any>(null)
    // const [fileName, setFileName] = useState(t('profile.no_file_changed'))

    const handleRemoveFile = (key: number) => {
        if (filesList) {
            const spliced = filesList.toSpliced(key, 1)
            console.log(spliced)
            setFilesList(spliced)
        }

    }

    const closeModalAndConfirm = () => {
        handleClose()
        setFilesListForSend(filesList)
    }

    return (
        createPortal(
            <>
                <Dialog open={open} handler={closeModalAndConfirm} className='!min-w-0 !max-w-[640px] bg-white p-[24px] tab:p-[32px] rounded-[16px] '>
                    <DialogBody className="p-0">
                        <div className="flex items-center justify-between mb-[32px]">
                            <Typography variant="lead" className="text-[24px] text-gray-800 font-medium ">
                                {t('new_connection.add_file_modal_title')}
                            </Typography>
                            <div onClick={closeModalAndConfirm} className="rounded-full w-[32px] h-[32px] transition-all flex items-center justify-center active:bg-gray-200 hover:bg-gray-100 cursor-pointer">
                                <CloseDrawerIcon />
                            </div>
                        </div>
                        <div className="flex items-center gap-[16px]">
                            <input
                                accept=''
                                type="file"
                                multiple
                                id="custom-input"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const files = e.target?.files
                                    if (files) setFilesList((prev: any) => ([...prev, ...Array.from(files)]))
                                }}
                                hidden
                            />
                            <label
                                htmlFor="custom-input"
                                className="block text-sm text-gray-700 mr-4 py-2 px-4
                                rounded-md border-0 text-sm font-semibold bg-gray-100 transition-all
                                hover:bg-gray-200 cursor-pointer"
                            >
                                {t('new_connection.choise_files')}
                            </label>
                        </div>
                        {
                            isIterableArray(filesList) &&
                            <List className='my-3'>
                                {
                                    Array.from(filesList).map((file: any, key: number) => (
                                        <ListItem key={key} ripple={false} >
                                            <span className='px-[16px]'>{file.name}</span>
                                            <ListItemSuffix>
                                                <IconButton variant="text" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={() => handleRemoveFile(key)}>
                                                    <TrashIcon />
                                                </IconButton>
                                            </ListItemSuffix>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        }
                        {/* <label className="text-sm text-slate-500">{fileName}</label> */}
                        <Button color='black' className='mt-[32px] w-full' onClick={closeModalAndConfirm}>
                            {t('new_connection.add_btn')}
                        </Button>
                    </DialogBody>
                </Dialog>
            </>,
            document.getElementById('root')!
        )
    )
}

export default ModalAddFiles