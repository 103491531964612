import { Typography } from '@material-tailwind/react'
import { Skeleton } from '@mui/material'

const SkeletonDeclared = () => {
    return (
        <>
            {
                Array.from(Array(3).keys()).map(key => (
                    <div key={key} className={`bg-white p-[16px] tab:p-[24px] lap:p-[48px] rounded-[16px] lap:rounded-[24px] mb-[24px]`}>
                        <div className='grid grid-cols-2 lap:grid-cols-[20%_20%_60%]'>
                            <div>
                                <Typography variant='lead' className='text-gray-700 font-[400] mb-[24px]'>
                                    <Skeleton />
                                </Typography>
                                <Typography variant='lead' className={` font-bold text-[16px]`}>
                                    <Skeleton className='max-w-[120px]' />
                                </Typography>
                                <Typography variant='lead' className={` font-bold text-[16px]`}>
                                    <Skeleton className='max-w-[120px]' />
                                </Typography>
                                <Typography variant='lead' className={` font-bold text-[16px]`}>
                                    <Skeleton className='max-w-[120px]' />
                                </Typography>
                            </div>
                            <div className='ml-[96px] hidden lap:block '>
                                <Typography variant='lead' className='text-gray-700 font-[400] mb-[8px]'>
                                    <Skeleton className='max-w-[120px]' />
                                </Typography>
                                <Typography variant='lead' className={` font-bold text-[32px]`}>
                                    <Skeleton className='max-w-[120px]' />
                                </Typography>
                            </div>
                            <div>
                                <div className='hidden tab:block'>
                                    <div className='grid grid-cols-4 lap:grid-cols-6 gap-[24px]'>
                                        <Skeleton variant='rectangular' className='min-h-[56px] my-[60px] rounded' />
                                        <Skeleton variant='rectangular' className='min-h-[56px] my-[60px] rounded' />
                                        <Skeleton variant='rectangular' className='min-h-[56px] my-[60px] rounded !hidden lap:!block' />
                                        <Skeleton variant='rectangular' className='min-h-[56px] my-[60px] rounded !hidden lap:!block' />
                                        <Skeleton variant='rectangular' className='min-h-[56px] my-[60px] rounded !hidden lap:!block' />
                                        <Skeleton variant='rectangular' className='min-h-[56px] my-[60px] rounded !hidden lap:!block' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default SkeletonDeclared